import React from 'react';
import SourceSVG from './utils/SourceSVG';

const Feature = ({ title, description, illustration }) => {
  return (
    <div className="border-[1px] w-full h-full border-black rounded-md px-10 py-14 flex flex-col items-center gap-8">
      {/*<ReactSVG src={illustration && illustration.url} />*/}
      {illustration && illustration.code && <SourceSVG data={illustration.code} />}
      <div className="text-left w-full flex flex-col gap-3">
        <h4 className="text-4xl font-regular text-gray-800 mt-4">{title}</h4>
        <p className="text-gray-600 font-regular text-lg whitespace-pre-line">{description}</p>
      </div>
    </div>
  );
};

export default Feature;