import React from 'react';

const HeaderL4 = ({ color, margin = false, children }) => {
    return (
        <h2
            className={`text-xl tracking-[0.025em] leading-tight whitespace-pre-line font-regular ${color === 'white' ? 'text-white' : 'text-black'} ${margin ? 'mb-5' : 'mb-0'}`}
        >
            {children}
        </h2>
    );
}

export default HeaderL4;