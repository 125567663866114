import React from 'react';
import Paragraph from '../atoms/Paragraph';

const TabContent = ({ children }) => {
    return (
        <div className="py-4 text-sm sm:block text-left">
            <Paragraph>{children}</Paragraph>
        </div>
    );
}

export default TabContent;