import React, { Suspense } from 'react';
import { request } from 'graphql-request';
import Value from '../molecules/Value';

// Sections
import Hero, { HERO_QUERY } from '../sections/Hero';
import Banner, { BANNER_QUERY } from '../Banner';
import FeatureList, { FEATURELIST_QUERY } from '../sections/FeatureList';
import ValueList, { VALUELIST_QUERY } from '../sections/ValueList';
import Slider, { SLIDER_QUERY } from '../sections/Slider';
import ProfileList, { PROFILELIST_QUERY } from '../sections/ProfileList';
import HauseHeader, { HAUSEHEADER_QUERY } from '../sections/HauseHeader';
import Tagline, { TAGLINE_QUERY } from '../sections/Tagline';
import Collection, { COLLECTION_QUERY } from '../sections/Collection';
import { useNavigate } from 'react-router-dom';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import MotionLogo from '../atoms/MotionLogo';


const buildPage = (data) => {

    if (!data) {
        return null;
    }

    const { sections } = data.page;

    return sections.map((section) => {
        return buildSection(section);
    });
};

const transitionDiv = `<div
  data-aos="fade-up"
  data-aos-offset="200"
  data-aos-delay="0"
  data-aos-duration="500"
  data-aos-easing="ease"
  data-aos-mirror="false"
  data-aos-once="false"
  data-aos-anchor-placement="top-center"
>`;

const buildSection = (section) => {
    console.log(section);
    switch (section.__typename) {
        case 'Hero':
            return (
                <Hero {...section} />
            );
        case 'FeatureList':
            return <FeatureList {...section} />;
        case 'ValueList':
            return <ValueList {...section} />;
        case 'ProfileList':
            return <ProfileList {...section} />
        case 'Banner':
            return <Banner {...section} />;
        case 'HauseHeader':
            return <HauseHeader {...section} />;
        case 'Slider':
            return <Slider {...section} />;
        case 'Tagline':
            return <Tagline {...section} />;
        case 'Collection':
            return <Collection {...section} />;
        case 'ContentReference':
            return buildSection(section.reference.type);
        default:
            return null;
    }
};

const Page = ({ slug }) => {
    const [data, setData] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const navigate = useNavigate();

    React.useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // Indicate that data is being loaded.
            const query = `
                query {
                    page(where: { slug: "${slug}"}) {
                        slug
                        title
                        navigation
                        sections {
                            __typename
                            ... on Hero ${HERO_QUERY}
                            ... on Banner ${BANNER_QUERY}
                            ... on FeatureList ${FEATURELIST_QUERY}
                            ... on ValueList ${VALUELIST_QUERY}
                            ... on Slider ${SLIDER_QUERY}
                            ... on ProfileList ${PROFILELIST_QUERY}
                            ... on HauseHeader ${HAUSEHEADER_QUERY}
                            ... on Tagline ${TAGLINE_QUERY}
                            ... on Collection ${COLLECTION_QUERY}
                            ... on ContentReference {
                                reference {
                                    __typename
                                    ... on Blob {
                                        id
                                        type {
                                            __typename
                                            ... on Banner ${BANNER_QUERY}
                                        }
                                    }
                                }
                            }
                        }
                    }
                }`

            const endpoint = 'https://eu-central-1-shared-euc1-02.cdn.hygraph.com/content/clbw1lyp8485w01t78e7n5p82/master'
            const data = await request(endpoint, query);
            setData(data);
            setLoading(false); // Indicate that data has been loaded.
        }
        fetchData()
    }, [])

    if (loading || !data) return <div className="min-h-full">Loading...</div>;

    return (
        <Suspense fallback={<div style={{
            minHeight: '100vh',
            minWidth: '100vw',
        }}>Loading...</div>}>
            <>
                {slug != "hello" &&
                    <div className='px-side-padding-sm lg:px-side-padding' style={{
                        width: '100%',
                        backgroundColor: '#faf6eb',
                        paddingTop: '4rem',
                        marginBottom: '-4rem',
                    }}>
                        <MotionLogo />
                    </div>}
                {buildPage(data)}
            </>
        </Suspense >
    );
};

export default Page;