import React from 'react';
import SocialLink from './buttons/SocialLink';
import MotionLogo from './atoms/MotionLogo';

const Footer = ({ color = '#242222' }) => {
    return (
        <footer className="flex flex-col justify-between items-center text-center py-12 px-side-padding-sm md:py-20 md:px-side-padding text-white w-full min-h-2/5" style={{
            background: color,
        }}>
            <div className="flex flex-col w-full gap-16 md:flex-row md:justify-between max-w-1400 h-full">
                {/* first column */}
                <div className="flex justify-content-start items-start w-32 text-left md:order-2 md:w-auto md:text-left">
                    <div className="flex gap-x-5 items center">
                        <MotionLogo />
                        <div className="flex items-center text-white text-left ml-0.5 font-bold text-2xl leading-none">
                            Hause
                            <br />
                            Studios
                        </div>
                    </div>
                </div>
                {/* right-side columns */}
                <div className="flex flex-col gap-8 md:gap-24 md:order-3 md:flex-row h-full">
                    {/* second column */}
                    <div className="w-auto text-left md:w-full md:text-left" style={{

                    }}>
                        <div>
                            <a href="mailto:hello@hause.co" className="whitespace-no-wrap">hello@hause.co</a>
                            <div className="flex flex-col mt-4 w-48 font-regular">
                                <p className="whitespace-no-wrap font-semibold">Hause Collective Pte. Ltd.</p>
                                <p className="whitespace-no-wrap">12 Purvis Street #02 - 1203</p>
                                <p className="whitespace-no-wrap">Singapore 188591</p>
                                <p className="whitespace-no-wrap">UEN: 202339419K</p>
                            </div>
                        </div>
                        <div>

                        </div>
                    </div>
                    {/* third column */}
                    {/* <div className="w-32 text-left md:w-full md:text-left">
                        <p>Contact</p>
                        <p>Careers</p>
                        <p>Services</p>
                        <p>Work</p>
                        <p>About</p>
    </div>*/}
                    {/* fourth column */}
                    <div className="flex flex-col w-32 text-left md:w-full md:text-left gap-3.5">
                        <SocialLink service="LinkedIn" />
                        <SocialLink service="Dribbble" />
                        <SocialLink service="Behance" />
                        <SocialLink service="Medium" />
                    </div>
                </div>
            </div>
            <div className="text-left w-full max-w-1400" style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            }}>
                <div>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/e/ee/Heart-shaped_Ukrainian_flag.svg" style={{
                        width: '40px',
                        height: 'auto',
                        position: 'relative',
                        marginBottom: '20px'
                    }} />
                </div>
                <div>
                    <p>© 2022-2024 Hause Collective. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;