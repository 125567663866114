import React from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import SimpleTable from '../molecules/SimpleTable'
import Section from '../Section'

const DynamicTitle = ({ item, color = '#000000' }) => {
    const variants = {
        hidden: {
            width: 0
        },
        visible: {
            width: '100%'
        }
    }

    {/*<AnimatePresence>
            <motion.div
                initial="hidden"
                animate="visible"
                //exit="hidden"
                variants={variants}
                style={{
                    //background: 'rgba(0, 0,0, 0.7)',
                }}
                className="h-72 w-screen mx-auto"
                //exit={{ "backdrop-filter": "blur(50px)" }}
                transition={{
                    duration: 1,
                    ease: "easeInOut",
                    repeat: 0,
                    delay: 0.2
                }}
            >*/}

    return (



        <Section padding={false} transparent={true}>
            <div
                className="relative left-0 w-full h-full flex items-center gap-x-16 pb-5 text-auto">
                <div className='flex max-w-1400 mx-auto w-full gap-x-32 items-start' style={{
                    flexDirection: 'column',
                    marginBottom: '200px',
                }}>
                    <h1 className="text-6xl w-full overflow-hidden font-regular text-left leading-[1.1em] text-white" style={{
                        //textShadow: '0px 0px 3px #FDC72D',
                        marginBottom: '50px',
                    }}>{item.title}</h1>
                    <div className="w-full">
                        <SimpleTable data={[['Client', item.client], ['Location', item.location], ['Services', item.tags]]} />
                    </div>
                </div>
            </div>
        </Section>
    )
}

export default DynamicTitle