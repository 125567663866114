import { useState, useEffect } from 'react';

const useBreakpoint = (breakpoints, containerWidth = null) => {
    const [currentBreakpoint, setCurrentBreakpoint] = useState('');

    const calculateCurrentBreakpoint = () => {
        const width = containerWidth !== null ? containerWidth : window.innerWidth;
        const matchingBreakpoints = Object.entries(breakpoints).filter(
            ([, breakpointWidth]) => width >= breakpointWidth
        );

        if (matchingBreakpoints.length) {
            const [newBreakpoint] = matchingBreakpoints.slice(-1)[0];
            setCurrentBreakpoint(newBreakpoint);
        } else {
            setCurrentBreakpoint('unknown');
        }
    };

    useEffect(() => {
        calculateCurrentBreakpoint();

        const handleResize = () => {
            calculateCurrentBreakpoint();
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [containerWidth]);

    return currentBreakpoint;
};

export default useBreakpoint;