import React, { useEffect, useState } from "react";
import { FaTwitter, FaLinkedin } from 'react-icons/fa';
import { FlagIcon } from 'react-flag-kit';
import { getCode } from 'country-list';
import { motion, useMotionValue, useTransform } from "framer-motion";
import { IoAdd } from 'react-icons/io5';

const Profile = ({ name, title, imageUrl, country, country2 = null, twitterLink, linkedinLink, joinUs = false }) => {

    let countryCode = "";
    let countryCode2 = "";

    if (!joinUs) {
        countryCode = getCode(country);

        if (country2) {
            countryCode2 = getCode(country2);
        }
    }

    if (country == "United States of America") {
        country = "United States"
    }

    const [rotateY, setRotateY] = useState(0);
    const [rotateX, setRotateX] = useState(0);
    const [angle, setAngle] = useState(15);

    const onMove = e => {
        // get position information for the card
        const bounds = e.currentTarget.getBoundingClientRect()

        // set x,y local coordinates
        const xValue = (e.clientX - bounds.x) / e.currentTarget.clientWidth
        const yValue = (e.clientY - bounds.y) / e.currentTarget.clientHeight

        // update state variables
        setRotateY(xValue * angle);
        setRotateX(yValue * angle);
    }

    const onLeave = () => {
        setRotateY(0);
        setRotateX(0);
    }

    if (country === "Taiwan") {
        countryCode = "TW"
    }

    if (country2 === "Taiwan") {
        countryCode2 = "TW";
    }

    if (country === "South Korea") {
        countryCode = "KR"
    }


    return (
        <div className="flex flex-row text-white w-auto relative bg-black rounded-3xl overflow-hidden bg-opacity-20 items-center gap-6 pt-0 px-0 shadow-lg min-h-[250px] items-stretch">
            {!joinUs &&
                <div style={{ display: 'flex', height: '100%', width: '100%', backgroundColor: 'rgba(255,255,255,0.1)' }}>
                    <div className="order-first w-full h-full flex flex-col items-stretch justify-center bg-gray-600">
                        <img
                            src={imageUrl}
                            alt={name}
                            className="w-full h-full relative object-cover"
                        />
                    </div>
                    <div className="flex flex-col items-start justify-between w-full p-8 pb-4 gap-x-5 gap-y-4 font-regular h-full">
                        <div className="flex flex-col justify-between text-left h-full">
                            <div>
                                <div>
                                    <h3 className="font-bold text-xl">{name}</h3>
                                    <div className="text-gray-500 text-sm">{title}</div>
                                </div>
                                <div className="flex flex-row items-start mt-1">
                                    {twitterLink && (
                                        <a
                                            href={twitterLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-gray-400 hover:text-white transition duration-150 ease-in-out"
                                        >
                                            <FaTwitter className="h-6 w-6" />
                                        </a>
                                    )}
                                    {linkedinLink && (
                                        <a
                                            href={linkedinLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-gray-400 hover:text-white transition duration-150 ease-in-out"
                                        >
                                            <FaLinkedin className="h-6 w-6" />
                                        </a>
                                    )}
                                </div>
                            </div>
                            <div>
                                {country2 && (
                                    <div className="flex items-left">
                                        {/* display the country flag */}
                                        <div className="relative order-first flex justify-left w-5"><FlagIcon code={countryCode2} className="rounded-full object-cover w-5 h-5" /></div>
                                        <div className="text-gray-500 text-sm ml-2">{country2}</div>
                                    </div>
                                )}
                                <div className="flex items-left mt-3">
                                    {/* display the country flag */}
                                    <div className="relative order-first flex justify-left w-5"><FlagIcon code={countryCode} className="rounded-full object-cover w-5 h-5" /></div>
                                    <div className="text-gray-500 text-sm ml-2">{country}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {
                joinUs &&
                <a href="https://tally.so/r/me5prE" target="_blank" rel="noopener noreferrer" style={{ width: '100%', height: '100%' }}>
                    <div className="w-full h-full flex flex-col items-center justify-center opacity-70 hover:opacity-100 transition-all">
                        <div>
                            <div className="justify-center">
                                <IoAdd className="text-8xl text-white" />
                            </div>
                            <div className="w-full font-regular h-full justify-center">
                                <h3 className="font-bold text-xl">Join us</h3>
                            </div>
                        </div>
                    </div>
                </a>
            }
        </div >
    );
};

export default Profile;