import React from 'react';
import Value from '../molecules/Value';
import Section from '../Section';
import HeaderL2 from '../atoms/HeaderL2';

const ValueList = ({ header, values }) => {
    return (
        <Section background="hause-beige">
            <HeaderL2 alignment="left">{header}</HeaderL2>
            <div className="flex h-full flex-col lg:flex-row justify-center lg:divide-x divide-black divide-opacity-0 gap-12">
                {values.map((value, index) => (
                    <div key={index} className={`w-full flex flex-row lg:w-1/${values.length}`}>
                        <Value {...value} />
                    </div>
                ))}
            </div>
        </Section>
    );
};

export const VALUELIST_QUERY = `
    {
        header
        values {
            animation {
                url
            }
            title
            description {
                raw
                html
                markdown
                text
            }
        }
    }
`;

export default ValueList;