import React from 'react';
import Tabbar from './molecules/Tabbar';
import Section from './Section';
import HeaderL2 from './atoms/HeaderL2';

const Services = () => {
    return (
        <Section>
            <HeaderL2 alignment="left">Our Services</HeaderL2>
            <Tabbar />
        </Section>
    );
}

export default Services;
