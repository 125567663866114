import React from 'react';
import Paragraph from '../atoms/Paragraph';

const TabIcon = ({ icon }) => {
    return (
        <div style={{
            backgroundImage: `url(${icon})`,
            width: '170px',
            height: '170px',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            borderRadius: '30px',
            marginBottom: '20px',
            filter: 'drop-shadow(5px 5px 20px rgba(0, 0, 0, 0.15))',
        }}></div>
    );
}

export default TabIcon;