import React, { useRef, useEffect } from "react";
import { ReactFitty } from "react-fitty";

const BigLogo = ({ text }) => {
    return (
        <div className="w-full text-hause-coral pt-20">
            {/* <ReactFitty className="overflow-hidden max-w-full tracking-tight leading-none font-black text-hause-coral saturate-120">
                {text}
             </ReactFitty> */ }
            <svg viewBox="0 0 1311 157" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.549805 153H30.9998V86.8499H90.0098V153H120.46V2.84987H90.0098V61.6499H30.9998V2.84987H0.549805V153Z" fill="currentColor" />
                <path d="M209.009 153H236.519V151.95C233.999 150.06 232.739 145.23 232.739 137.04V82.2299C232.739 57.0299 219.719 42.5399 187.379 42.5399C155.879 42.5399 141.809 59.1299 140.969 78.0299H166.379C167.219 67.7399 173.729 62.9099 187.169 62.9099C199.139 62.9099 205.229 67.9499 205.229 75.7199C205.229 83.9099 197.039 85.3799 179.189 87.8999C155.249 91.2599 135.929 98.8199 135.929 122.55C135.929 143.97 151.469 155.52 173.519 155.52C191.999 155.52 199.769 149.22 205.229 141.03H205.649C206.279 145.86 207.329 150.9 209.009 153ZM180.449 135.57C170.369 135.57 163.649 132 163.649 121.92C163.649 111.84 170.999 108.48 185.909 105.12C193.049 103.44 200.819 101.76 205.859 98.8199V114.99C205.859 125.49 196.829 135.57 180.449 135.57Z" fill="currentColor" />
                <path d="M346.446 153V45.2699H317.886V107.43C317.886 121.71 309.696 131.79 296.256 131.79C284.076 131.79 278.406 124.86 278.406 112.26V45.2699H250.056V117.09C250.056 140.61 263.496 155.94 287.436 155.94C302.556 155.94 310.956 150.27 318.306 140.4H318.936V153H346.446Z" fill="currentColor" />
                <path d="M408.6 156.15C435.48 156.15 453.96 143.13 453.96 121.5C453.96 96.2999 434.009 91.2599 415.949 87.4799C400.619 84.3299 386.34 83.4899 386.34 74.2499C386.34 66.4799 393.69 62.2799 404.82 62.2799C417 62.2799 424.35 66.4799 425.609 78.0299H451.44C449.34 56.3999 433.59 42.3299 405.24 42.3299C380.669 42.3299 361.35 53.4599 361.35 76.7699C361.35 100.29 380.25 105.54 399.57 109.32C414.27 112.26 427.919 113.31 427.919 123.6C427.919 131.16 420.78 135.99 408.18 135.99C395.37 135.99 386.55 130.53 384.66 118.14H358.199C359.879 141.03 377.31 156.15 408.6 156.15Z" fill="currentColor" />
                <path d="M518.479 156.15C546.199 156.15 563.209 139.98 567.199 120.87H539.059C535.909 129.06 529.399 134.1 518.269 134.1C502.099 134.1 492.859 123.81 490.759 107.22H568.879C568.879 68.7899 550.399 42.3299 515.749 42.3299C484.249 42.3299 462.619 67.1099 462.619 99.0299C462.619 131.16 482.779 156.15 518.479 156.15ZM516.169 64.3799C529.189 64.3799 538.219 73.8299 539.059 87.4799H491.179C493.699 73.4099 501.049 64.3799 516.169 64.3799Z" fill="currentColor" />
                <path d="M677.851 156.36C711.661 156.36 736.231 139.35 736.231 108.9C736.231 76.5599 711.031 69.2099 680.161 62.4899C657.901 57.6599 647.401 54.0899 647.401 41.9099C647.401 31.1999 658.111 24.2699 674.491 24.2699C690.031 24.2699 701.791 31.8299 703.471 46.9499H732.661C730.351 16.7099 708.511 0.119873 674.701 0.119873C641.311 0.119873 617.161 15.4499 617.161 45.0599C617.161 76.3499 641.731 83.4899 668.611 89.5799C691.501 94.8299 705.781 97.7699 705.781 112.47C705.781 126.33 692.131 131.79 676.591 131.79C655.171 131.79 644.881 124.65 642.781 106.59H612.961C614.011 138.3 637.111 156.36 677.851 156.36Z" fill="currentColor" />
                <path d="M744.782 64.1699H759.692V127.38C759.692 147.75 775.022 153.21 788.882 153.21C800.222 153.21 806.732 152.79 806.732 152.79V131.79C806.732 131.79 801.902 132 798.332 132C792.032 132 787.622 129.27 787.622 121.29V64.1699H806.102V45.2699H787.622V11.6699H759.692V45.2699H744.782V64.1699Z" fill="currentColor" />
                <path d="M916.358 153V45.2699H887.798V107.43C887.798 121.71 879.608 131.79 866.168 131.79C853.988 131.79 848.318 124.86 848.318 112.26V45.2699H819.968V117.09C819.968 140.61 833.408 155.94 857.348 155.94C872.468 155.94 880.868 150.27 888.218 140.4H888.848V153H916.358Z" fill="currentColor" />
                <path d="M975.152 156.15C990.272 156.15 1000.98 149.22 1007.28 138.93H1007.7V153H1035.21V2.84987H1006.65V58.2899H1006.02C1000.14 49.2599 990.692 42.3299 975.572 42.3299C948.692 42.3299 929.582 65.6399 929.582 99.2399C929.582 134.1 948.062 156.15 975.152 156.15ZM981.452 132.21C966.752 132.21 958.562 120.03 958.562 99.2399C958.562 79.2899 966.542 65.4299 982.292 65.4299C1000.56 65.4299 1007.28 79.2899 1007.28 99.8699C1007.28 119.61 998.462 132.21 981.452 132.21Z" fill="currentColor" />
                <path d="M1053.78 153H1082.34V45.2699H1053.78V153ZM1053.78 28.4699H1082.34V2.84987H1053.78V28.4699Z" fill="currentColor" />
                <path d="M1151.77 156.15C1185.16 156.15 1208.05 131.37 1208.05 99.2399C1208.05 67.1099 1185.16 42.3299 1151.77 42.3299C1118.38 42.3299 1095.49 67.1099 1095.49 99.2399C1095.49 131.37 1118.38 156.15 1151.77 156.15ZM1151.77 134.31C1134.13 134.31 1124.47 120.24 1124.47 99.2399C1124.47 78.2399 1134.13 63.9599 1151.77 63.9599C1169.2 63.9599 1179.07 78.2399 1179.07 99.2399C1179.07 120.24 1169.2 134.31 1151.77 134.31Z" fill="currentColor" />
                <path d="M1265.01 156.15C1291.89 156.15 1310.37 143.13 1310.37 121.5C1310.37 96.2999 1290.42 91.2599 1272.36 87.4799C1257.03 84.3299 1242.75 83.4899 1242.75 74.2499C1242.75 66.4799 1250.1 62.2799 1261.23 62.2799C1273.41 62.2799 1280.76 66.4799 1282.02 78.0299H1307.85C1305.75 56.3999 1290 42.3299 1261.65 42.3299C1237.08 42.3299 1217.76 53.4599 1217.76 76.7699C1217.76 100.29 1236.66 105.54 1255.98 109.32C1270.68 112.26 1284.33 113.31 1284.33 123.6C1284.33 131.16 1277.19 135.99 1264.59 135.99C1251.78 135.99 1242.96 130.53 1241.07 118.14H1214.61C1216.29 141.03 1233.72 156.15 1265.01 156.15Z" fill="currentColor" />
            </svg>

        </div>
    );
};

export default BigLogo;