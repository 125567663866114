import React, { useState } from 'react';
import Modal from 'react-modal';
import { X } from 'react-feather';

const ExternalLinkModal = ({ isOpen, url, onClose, size }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            shouldCloseOnOverlayClick={true}
            className="fixed w-11/12 md:w-3/4 h-3/4 max-h-screen top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 bg-white rounded-lg shadow-md"
            overlayClassName="fixed z-40 top-0 left-0 w-full h-full bg-black bg-opacity-75 backdrop-blur-md"
            contentLabel="External URL Modal"
            appendTo={document.body}
        >
            <div className="relative p-0 h-full rounded-md overflow-hidden">
                <div className="absolute top-0 right-0 p-2">
                    <button onClick={() => onClose()} className="text-black hover:opacity-60 transition-opacity">
                        <X size={25} />
                    </button>
                </div>
                <iframe src={url} width="100%" height="100%" title="External URL" />
            </div>
        </Modal>
    );
}

export default ExternalLinkModal;