import React, { useState, useEffect, Suspense } from 'react';
import { request } from 'graphql-request';
import Card from '../molecules/Card';
import Section from '../Section';
import PerspectiveHover from '../effects/PerspectiveHover';
import PageTransition from '../PageTransition';
import CollectionAsset, { COLLECTIONASSET_QUERY } from '../atoms/CollectionAsset';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '../../App';
import ScrollIndicator from '../effects/ScrollIndicator';
import Banner from '../Banner';
import { motion, AnimatePresence } from 'framer-motion';

const Collection = ({ type }) => {
  const navigate = useNavigate();
  const { expandedCard, setExpandedCard } = useContext(AppContext);

  const animationData = {
    url: 'https://media.graphassets.com/o5YOHU9NQsCROQ3JCf7b', // Replace with the path to your Lottie animation JSON file
    mimeType: 'application/json'
  };

  const endpoint = 'https://eu-central-1-shared-euc1-02.cdn.hygraph.com/content/clbw1lyp8485w01t78e7n5p82/master';
  let query;
  switch (type) {
    case 'work':
      query = `
        {
          works(orderBy: order_ASC) {
            slug
            title
            brandColor {
              hex
            }
            logo {
              url
            }
            screen {
              url
            }
            screenHi {
              url
            }
            video {
              url
            }
            client
            location
            coverElements {
                __typename
                ...on CollectionAsset ${COLLECTIONASSET_QUERY}
            }
            thumbnail {
              url
            }
            tags
          }
        }
      `;
      break;
    case 'article':
      query = `
        {
          articles {
            title
            author
            thumbnail {
              url
            }
            tags
          }
        }
      `;
      break;
  }
  const fetchData = async () => {
    const data = await request(endpoint, query);
    return data;
  };
  const [data, setData] = useState([]);
  useEffect(() => {
    fetchData().then((result) => {
      setData(result);
      handlePathnameChange(result);
    });
  }, []);

  const handlePathnameChange = (result) => {

    const currentPathname = window.location.pathname;
    if (currentPathname.startsWith('/work/')) {
      const currentWorkSlug = currentPathname.split('/')[2];
      // compare currentWorkSlug to the slug of each work item in your state
      // and set isExpanded to true if there is a match


      result.works && result.works.map((item) => {
        if (currentWorkSlug === item.slug) {
          setExpandedCard(true, item.slug);
          console.log(expandedCard.slug);
        }
      })
    } else {
      setExpandedCard(false, null);
    }
  };

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    // This checks whether the works data is present and sets the isReady state accordingly
    if (data.works) {
      setIsReady(true);
    }
  }, [data]);

  if (!isReady) {
    return (
      <div style={{
        minHeight: '100vh',
        minWidth: '100vw',
      }}>
        Loading...
      </div>
    );
  }

  console.log(data);
  return (
    <Suspense fallback={<div style={{
      minHeight: '100vh',
      minWidth: '100vw',
    }}>Loading...</div>}>
      <Section maxWidth={true} minHeight={false} >
        {data.works && <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-8 -mt-20">
          {data.works.map((item) => (
            <div className="w-full h-full rounded-3xl" onClick={() => {
              navigate(`/work/${item.slug}`);
              handlePathnameChange(data);
            }}>
              <Card type={type} item={item} isExpanded={expandedCard.active && expandedCard.slug === item.slug} />
            </div>
          ))}
        </div>}
      </Section>
      <div className="-mt-20 w-full">
        <Banner header="Interested in seeing more projects?" animation={animationData} paragraph="...then click here to go to our full collection!" externalUrl='https://hause.notion.site/Hause-Portfolio-Showcase-0648f44abdf1442e904c7fe8ade32791' openInNewTab={true} />
      </div>
    </Suspense>
  );
};

export const COLLECTION_QUERY = `
    {
        type
    }
`;

export default Collection;