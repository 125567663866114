import React, { useState, useEffect, useRef } from 'react'
// Sections
//import { Hero, FeatureList, ValueList, ProfileList, Banner, HauseHeader, Slider, Tagline, Collection, ContentReference } from '../constants/Sections';
import Hero from '../sections/Hero';
import Banner, { BANNER_QUERY } from '../Banner';
import FeatureList, { FEATURELIST_QUERY } from '../sections/FeatureList';
import ValueList, { VALUELIST_QUERY } from '../sections/ValueList';
import Slider, { SLIDER_QUERY } from '../sections/Slider';
import ProfileList, { PROFILELIST_QUERY } from '../sections/ProfileList';
import HauseHeader, { HAUSEHEADER_QUERY } from '../sections/HauseHeader';
import Tagline, { TAGLINE_QUERY } from '../sections/Tagline';
import Collection, { COLLECTION_QUERY } from '../sections/Collection';
import Column from '../sections/Column';
import Quote from '../sections/Quote';
import useBreakpoint from '../useBreakpoint';



const buildSection = (section, breakpoint) => {
    switch (section.__typename) {
        case 'Hero':
            return <Hero {...section} />;
        case 'FeatureList':
            return <FeatureList {...section} />;
        case 'ValueList':
            return <ValueList {...section} />;
        case 'ProfileList':
            return <ProfileList {...section} />
        case 'Banner':
            return <Banner {...section} />;
        case 'HauseHeader':
            return <HauseHeader {...section} />;
        case 'Slider':
            return <Slider {...section} />;
        case 'Tagline':
            return <Tagline {...section} />;
        case 'Collection':
            return <Collection {...section} />;
        case 'Quote':
            return <Quote {...section} />;
        case 'Column':
            return <Column breakpoint={breakpoint} {...section} />;
        case 'ContentReference':
            return buildSection(section.reference.type);
        default:
            return null;
    }
};

const BuildSection = ({ section }) => {

    const breakpoints = {
        xs: 0,
        sm: 768,
        md: 992,
        lg: 1200,
        xl: 1600,
    };

    const mainContainerRef = useRef(null);
    const [containerWidth, setContainerWidth] = useState(null);

    useEffect(() => {
        const updateContainerWidth = () => {
            if (mainContainerRef.current) {
                setContainerWidth(mainContainerRef.current.clientWidth);
            }
        };

        updateContainerWidth();

        const resizeObserver = new ResizeObserver(updateContainerWidth);
        if (mainContainerRef.current) {
            resizeObserver.observe(mainContainerRef.current);
        }

        window.addEventListener('resize', updateContainerWidth);

        return () => {
            if (mainContainerRef.current) {
                resizeObserver.unobserve(mainContainerRef.current);
            }
            window.removeEventListener('resize', updateContainerWidth);
        };
    }, []);

    const breakpoint = useBreakpoint(
        breakpoints,
        containerWidth
    );



    return buildSection(section, breakpoint);
};

export default BuildSection;