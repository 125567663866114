import React, { useEffect, useState, useRef } from 'react';
import lottie from 'lottie-web';
import Placeholder from './atoms/Placeholder';

const Animation = ({ animationData }) => {
  const animationContainer = useRef(null);
  const animationInstance = useRef(null);
  const [animationType, setAnimationType] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  // Initialize new loading state
  const [isLoading, setIsLoading] = useState(true);

  const handleResize = () => {
    if (animationContainer.current) {
      animationContainer.current.style.height = 'auto';
    }
  };

  const loadAnimation = () => {
    if (!animationData || !animationData.url) {
      setIsLoading(false);
      return;
    }

    if (animationData.mimeType !== 'image/svg+xml') {
      setAnimationType('lottie');
      animationInstance.current = lottie.loadAnimation({
        container: animationContainer.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: animationData.url,
      });

      animationInstance.current.addEventListener('DOMLoaded', () => {
        window.addEventListener('resize', handleResize);
        // Set loading to false when animation is loaded
        setIsLoading(false);
      });

      animationInstance.current.addEventListener('error', () => {
        if (retryCount < 3) {
          setRetryCount(retryCount + 1);
        }
        // Set loading to false when there's an error loading animation
        setIsLoading(false);
      });
    } else if (animationData.mimeType === 'image/svg+xml') {
      setAnimationType('svg');
      // Set loading to false when animation is loaded
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadAnimation();
    return () => {
      if (animationInstance.current) {
        animationInstance.current.destroy();
      }
      window.removeEventListener('resize', handleResize);
    };
  }, [animationData, retryCount]);


  return (
    <div className="relative flex flex-row w-full saturate-100 p-0"
      style={{

      }}>
      {isLoading ? (
        <Placeholder />
      ) : (
        animationData && animationData.url ? (
          animationType === 'lottie' ? (
            <div ref={animationContainer} className="w-full h-auto p-0" width="100%" height="100%" />
          ) : (
            <object data={animationData.url} type="image/svg+xml" width="100%" height="100%"></object>
          )
        ) : null
      )}
    </div>
  );
};

export default Animation;