import * as React from "react";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { wrap } from "popmotion";
import { useDrag } from "react-use-gesture";
import Slide from "../molecules/Slide";
import Column, { COLUMN_QUERY } from "./Column";
import Table from "../effects/Table";

const variants = {
    enter: (direction) => {
        return {
            x: direction > 0 ? 1000 : -1000,
            opacity: 0,
        };
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1
    },
    exit: (direction) => {
        return {
            zIndex: 0,
            x: direction < 0 ? 1000 : -1000,
            opacity: 0
        };
    }
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
};

export const Slider = ({ tagline, slides, button }) => {
    const [[page, direction], setPage] = useState([0, 0]);

    const contentIndex = wrap(0, slides.length, page);

    const paginate = (newDirection) => {
        setPage([page + newDirection, newDirection]);
    };

    const bind = useDrag(({ down, movement: [mx], velocity }) => {
        if (!down) {
            const swipe = swipePower(mx, velocity);

            if (swipe < -swipeConfidenceThreshold) {
                paginate(1);
            } else if (swipe > swipeConfidenceThreshold) {
                paginate(-1);
            }
        }
    });

    console.log(slides);

    const slide = slides[contentIndex];

    return (
        <div className="relative w-full bg-hause-dark overflow-hidden h-auto pointer-events-none" style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            //minHeight: '80vh',
        }}>
            <div className="absolute top-0 right-0 bottom-0 w-4/5 h-auto z-0 pointer-events-auto sm:hidden md:block">
                {/*<Table />*/}
            </div>
            <div className="absolute top-0 right-0 bottom-0 w-4/5 h-auto z-90 pointer-events-none" style={{

            }} />
            <AnimatePresence initial={false} custom={direction}>
                <motion.div
                    key={page}
                    custom={direction}
                    variants={variants}
                    initial="enter"
                    animate="center"
                    exit={{ position: "absolute" }}
                    transition={{
                        x: { type: "spring", stiffness: 300, damping: 30 },
                        opacity: { duration: 0.2 },
                    }}
                    className="relative w-full h-full"
                    {...bind()}
                >
                    <div className="relative z-100 h-auto lg:h-full lg:w-[45%] pointer-events-none">
                        <Slide {...slide} />
                    </div>
                </motion.div>
            </AnimatePresence>
            <div className="next pointer-events-auto" onClick={() => paginate(1)}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                </svg>
            </div>
            <div className="prev pointer-events-auto" onClick={() => paginate(-1)}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                </svg>
            </div>
        </div>
    );
};

export const SLIDER_QUERY = `
    {
        tagline
        slides {
            preHeader
            header
            primary {
                __typename
                ... on Column ${COLUMN_QUERY}
            }
        }
        button {
            text
        }
    }
`;


export default Slider;
