import React, { useEffect, useState } from "react";
import { motion, useMotionValue, useTransform } from "framer-motion";
import classnames from 'classnames';

interface PerspectiveHoverProps {
    children: React.ReactNode;
    shadow: boolean;
    scale: boolean;
    perspective: boolean;
    enabled: boolean;
}

const PerspectiveHover: React.FC<PerspectiveHoverProps> = ({ shadow = true, enabled = true, scale = false, perspective = true, children }) => {
    const [rotateY, setRotateY] = useState(0);
    const [rotateX, setRotateX] = useState(0);
    const [angle, setAngle] = useState(15);

    const onMove = (e: React.PointerEvent<HTMLDivElement>) => {
        if (!enabled) {
            setRotateY(0);
            setRotateX(0);
            return;
        }

        // get position information for the element
        const bounds = e.currentTarget.getBoundingClientRect();

        // set x,y local coordinates
        const xValue = (e.clientX - bounds.x) / e.currentTarget.clientWidth;
        const yValue = (e.clientY - bounds.y) / e.currentTarget.clientHeight;

        // update state variables
        setRotateY(xValue * angle);
        setRotateX(yValue * angle);
    };

    const onLeave = () => {
        setRotateY(0);
        setRotateX(0);

        if (!enabled) {
            return;
        }
    };

    // useEffect hook to execute function when enabled changes
    useEffect(() => {
        if (!enabled) {
            setRotateY(0);
            setRotateX(0);
        }
    }, [enabled]);


    return (
        <motion.div
            onPointerMove={perspective ? onMove : undefined}
            onPointerLeave={perspective ? onLeave : undefined}
            whileHover={enabled && scale ? { scale: 1.05 } : undefined}
            whileTap={enabled ? { scale: 0.95 } : undefined}
            style={{
                rotateY,
                rotateX,
                transition: "all 0.3s ease-out",
            }}
            className={classnames({
                "hover:shadow-lg": shadow && enabled,
                "hover:saturate-100": enabled,
                "hover:contrast-100": enabled,
                "rounded-3xl": enabled,
                "cursor-pointer": enabled,
                "w-full": enabled,
            })}
        >
            {children}
        </motion.div>
    );
};

export default PerspectiveHover;