import React from 'react';
import Section from '../Section';
import Markdown from 'markdown-to-jsx';
import ReactDom from 'react-dom'
import HeaderL1 from '../atoms/HeaderL1';
import { render } from 'react-dom';
import Paragraph from '../atoms/Paragraph';
import HeaderL3 from '../atoms/HeaderL3';

const options = {
    overrides: {
        h1: {
            component: HeaderL1
        },
        h3: {
            component: HeaderL3,
            props: {
                margin: true,
            }
        },
        p: {
            component: Paragraph
        },
        span: {
            component: Paragraph
        }
    }
};

const Column = ({ breakpoint, segment, content, integrated }) => {

    if (breakpoint === "xs" || breakpoint === "sm") {
        segment = "full";
    }

    let classname = "";
    if (content.length === 1) {
        classname = "columns-1"
    } else if (content.length === 2) {
        classname = "columns-2"
    } else if (content.length === 3) {
        classname = "columns-3"
    } else if (content.length === 4) {
        classname = "columns-4"
    }

    const column = content.map((col, index) => {
        return <div key={index} className={`paragraph text-left flex`}>
            <Markdown options={options}>{col}</Markdown>
        </div>
    });

    const result =
        <div className={`${segment === "left" || segment === "right" ? "w-1/2" : "w-full"} ${segment === "left" ? "ml-0" : ""} ${segment === "right" ? "mr-0" : ""} justify-between mx-auto flex ${classname}`}>
            {column}
        </div>

    if (integrated) return result;

    return (
        <Section maxWidth="true" minHeight={false} integrated={integrated}>
            {result}
        </Section>
    );
}

export const COLUMN_QUERY = `
    {
        segment
        integrated
        content
    }
`;

export default Column;