import { Suspense, lazy, useState, useEffect, useRef, useMemo } from 'react';
import { motion, useCycle } from 'framer-motion';

const HighResImage = lazy(() => import('./HighResImage'));  // you need to create this component

const generateInitialBackgroundPositions = () => {
    return Math.random() < 0.5 ? ['0%', '100%'] : ['100%', '0%'];
};

const generateDelay = () => {
    return Math.floor(Math.random() * 4000) + 2000;
};

const Background = ({ item, isExpanded, scrollingDivRef }) => {
    const initialBackgroundPositions = useMemo(generateInitialBackgroundPositions, []);
    const [backgroundPositionY, cycleBackgroundPositionY] = useCycle(...initialBackgroundPositions);

    const delay = useMemo(generateDelay, []);

    const containerRef = useRef(null);

    useEffect(() => {
        if (isExpanded === false) {
            containerRef.current.style.transform = 'scale(1)';
        }
    }, [isExpanded === false]);

    useEffect(() => {
        const handleScroll = () => {

            const container = containerRef.current;
            const scrollPosition = scrollingDivRef.current.scrollTop;

            if (scrollPosition <= 0) {
                container.style.transform = 'scale(1)';
            } else if (scrollPosition <= 600) {
                const scale = 1 - 0.4 * (scrollPosition / 600);
                container.style.transform = `scale(${scale})`;
            } else {
                container.style.transform = 'scale(0.6)';
            }
        };

        scrollingDivRef.current.addEventListener('scroll', handleScroll);

        return () => {
            if (scrollingDivRef.current) {
                scrollingDivRef.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, [isExpanded === true]);

    const handleAnimationComplete = () => {
        setTimeout(() => {
            cycleBackgroundPositionY();
        }, delay);
    };

    return (
        <div
            className=""
            ref={containerRef}
            style={{
                //transitionDuration: isExpanded ? '1s' : '',
                //transitionTimingFunction: isExpanded ? 'ease' : '',
                position: isExpanded ? 'relative' : 'absolute',
                height: isExpanded ? '100%' : '85%',
                right: !isExpanded ? '-3rem' : '',
                width: isExpanded ? '100%' : '60%',
                maxWidth: isExpanded ? '2000px' : '',
                marginLeft: isExpanded ? '0px' : '',
                marginRight: isExpanded ? '100px' : '',
                overflow: 'hidden',
                borderTopLeftRadius: !isExpanded ? '0.3rem' : '1.1rem',
                borderBottomLeftRadius: !isExpanded ? '0.3rem' : '1.1rem',
                borderTopRightRadius: !isExpanded ? '0rem' : '1.1rem',
                borderBottomRightRadius: !isExpanded ? '0rem' : '1.1rem',
                zIndex: 1,
            }}
        >
            <motion.div
                className="bg absolute top-0 left-0 w-full h-full bg-no-repeat bg-cover"
                style={{ position: 'absolute', top: 0, left: 0 }}
            >
                {/* Low-resolution image */}
                {!isExpanded && (
                    <motion.div
                        className="w-full h-full bg-no-repeat bg-cover"
                        style={{ backgroundImage: `url(${item.screen.url})` }}
                        animate={{ backgroundPositionY: backgroundPositionY }}
                        onAnimationComplete={() => handleAnimationComplete()}
                        transition={{ duration: 6 }}
                    />
                )}

                {/* High-resolution image */}
                {isExpanded && (
                    <Suspense fallback={<div>Loading...</div>}>
                        <HighResImage item={item} backgroundPositionY={backgroundPositionY} handleAnimationComplete={handleAnimationComplete} />
                    </Suspense>
                )}
            </motion.div>

            {!isExpanded &&
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: item.brandColor.hex,
                        opacity: 0.3,
                    }}
                />}

            <motion.div
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                animate={{ backgroundPositionY: backgroundPositionY }}
                transition={{ duration: 6 }}
            />

            {/* We can use the isExpanded prop directly in the className of the container div */}
            <div className={`w-full h-full absolute top-0 left-0 ${isExpanded ? 'cursor-pointer' : ''}`} />
        </div>
    );
};

export default Background;