import React from 'react';

const Paragraph = ({ children }) => {
    return (
        <p className="font-base text-2xl font-regular leading-relaxed whitespace-pre-line mb-4">
            {children}
        </p>
    );
}

export default Paragraph;