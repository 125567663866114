import React from 'react';

const HeaderL3 = ({ color, margin = false, children }) => {
    return (
        <h2
            className={`text-4xl leading-tight whitespace-pre-line font-light ${color === 'white' ? 'text-white' : 'text-black'} ${margin ? 'mb-5' : 'mb-0'}`}
        >
            {children}
        </h2>
    );
}

export default HeaderL3;