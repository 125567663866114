import React from 'react';
import { useState } from 'react';

const TabHeader = ({ key, pre, title, onClick, active }) => {
    const [hover, setHover] = useState(false);

    const handleMouseEnter = () => {
        setHover(true);
    };

    const handleMouseLeave = () => {
        setHover(false);
    };

    return (
        <div
            className={`text-lg py-8 font-medium px-0 cursor-pointer border-b items-start text-left sm:text-left`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={onClick}
            style={{
                transition: 'all ease 0.8s',
                boxShadow: hover && !active ? 'inset 100vw 0 0 0 #fae9db' : 'inset 0 0 0 0 #fae9db',
                paddingLeft: hover ? '2rem' : '0',
            }}
        >
            <div className="flex flex-row items-center gap-x-3">
                <div
                    className={`${active ? 'bg-hause-coral text-white' : 'bg-transparent'
                        } flex items-center justify-center text-xl p-3 rounded-full font-bold w-12 h-12`}
                >
                    {pre}
                </div>
                <p className={`${active ? 'text-hause-coral' : ''} text-3xl sm:text-xl font-regular`}>{title}</p>
            </div>
        </div>
    );
};

export default TabHeader;