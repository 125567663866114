import React, { useState, useEffect } from 'react';
import './App.css';
import './main.css';
import Navbar from './components/Navbar';
import SwipeableNavbar from './components/SwipeableNavbar';
import Footer from './components/Footer';
import Frontpage from './components/content/Frontpage';
import About from './components/molecules/About';
import Page from './components/content/Page';
import PageTransition from './components/PageTransition';
import { Waypoint } from 'react-waypoint';
import { createContext } from 'react';
import { toggleBodyOverflow } from './utilities';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import Services from './components/Services';
import { inject } from '@vercel/analytics';
import MotionLogoWhite from './components/atoms/MotionLogoWhite';

inject();

export const AppContext = createContext();

const App = () => {
  const [contentZIndex, setContentZIndex] = useState(1);
  const [currentLayout, setCurrentLayout] = useState('hello');
  const [showLogoText, setShowLogoText] = useState(false);
  const [expandedCard, setExpandedCard] = useState([false, null]);

  const handleSetExpandedCard = (active, slug = null) => {
    toggleBodyOverflow(active ? 'hidden' : 'scroll');
    setExpandedCard({ active, slug });
  }

  const location = useLocation();
  const [slug, setSlug] = useState('');

  useEffect(() => {
    const navbarHeight = document.querySelector('#navbar').offsetHeight;
    //document.querySelector('#content').style.paddingTop = `${navbarHeight}px`;
  }, [slug]);

  useEffect(() => {
    const currentSlug = location.pathname.substring(1);

    switch (currentSlug) {
      case '':
        setSlug('hello');
        break;
      case 'work/':
        setSlug('work');
        break;
      case 'work':
        setSlug('work');
        break;
      case 'about':
        setSlug('about');
        break;
      case 'services':
        setSlug('services');
        break;
      default:
        if (currentSlug.startsWith('work')) {
          setSlug('work');
        }
        else {
          setSlug('hello');  // Or set to a 404 page or other default.
        }
        break;
    }
  }, [location]);


  const updateZIndex = (zIndex) => {
    setContentZIndex(zIndex);
    console.log(contentZIndex);
  }



  const changeLayout = (layout) => {
    console.log(layout);
    toggleBodyOverflow("scroll");
    setCurrentLayout(layout);
    setExpandedCard({
      active: false,
      slug: null,
    });
  }

  const toggleLogoText = () => {
    setShowLogoText(prevState => !prevState);
  }

  const initialContextValue = {
    contentZIndex,
    currentLayout,
    showLogoText,
    expandedCard,
    setExpandedCard: handleSetExpandedCard,
    updateZIndex
  }
  return (
    <div className="App flex flex-col items-center w-full">
      <Waypoint
        topOffset={-170}
        onEnter={() => setShowLogoText(false)}
        onLeave={() => setShowLogoText(true)}
      />

      <AppContext.Provider value={initialContextValue}>
        <Navbar logoText={showLogoText} changeLayout={changeLayout} current={currentLayout} />
        <div style={{
          position: 'absolute',
          width: '50px',
          height: '50px',
          backgroundColor: 'white',
          top: '-50px',
          left: 'calc(50% - 50px)',
          zIndex: 999,
        }}>
          <MotionLogoWhite />
        </div>
        <div id="content" className={`w-full flex items-center justify-content-center align-items-center p-0 mt-0 z-${contentZIndex}`} style={{ zIndex: contentZIndex }}>
          <PageTransition pageKey={currentLayout}>
            {/*{currentLayout === 'frontpage' && <Frontpage showLogoText={showLogoText} />}
            {currentLayout === 'about' && <About />}
  {currentLayout === 'services' && <Services />}*/}
            {slug === 'about' && <About />}
            <Page slug={slug} />
            {slug === 'services' && <Services />}
          </PageTransition>
        </div>
        <Footer changeLayout={changeLayout} current={currentLayout} />
      </AppContext.Provider>
    </div>
  );
}

export default App;