import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const ScrollIndicatorWrapper = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 8px;
  z-index: 999999;
`;

const ScrollIndicatorBar = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 8px;
  z-index: 999999;
  background-color: orange;
`;

const ScrollIndicator = ({ element = window, color = '#000000', breakpoint }) => {
    const [scrollPercentage, setScrollPercentage] = useState(0);
    const indicatorRef = useRef(null);

    const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = element.current;
        const scrollFraction = scrollTop / (scrollHeight - clientHeight);
        setScrollPercentage(scrollFraction * 100);
    };

    useEffect(() => {
        element.current.addEventListener('scroll', handleScroll);
        return () => {
            element.current.removeEventListener('scroll', handleScroll);
        };
    }, [element]);

    useEffect(() => {
        if (scrollPercentage === 0) {
            indicatorRef.current.style.opacity = 0;
        } else {
            indicatorRef.current.style.opacity = 1;
        }
    }, [scrollPercentage]);

    return (
        <div ref={indicatorRef} style={{
            position: 'fixed',
            bottom: breakpoint === 'xs' || breakpoint === 'sm' || breakpoint === 'md' ? '65px' : '0px',
            left: 0,
            height: '8px',
            border: '1px solid rgba(255,255,255,0.5)',
            zIndex: 999999,
            backgroundColor: color,
            filter: 'brightness(1.15)',
            filter: 'contrast(1.3)',
            width: `${scrollPercentage}%`,
        }} />
    );
};

export default ScrollIndicator;