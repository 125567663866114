import React, { useState, useEffect, Suspense } from 'react'
import Tagline from '../atoms/Tagline';
import Animation from '../Animation';
import CTA from '../buttons/CTA';
import HeaderL1 from '../atoms/HeaderL1';
import Section from '../Section';

// HeaderParagraphCTA component
function HeaderParagraphCTA(props) {
    const { title, tagline, button } = props;
    return (
        <div className="flex flex-col items-center lg:items-start py-12 lg:max-w-2xl gap-y-4">
            <h1 className="text-4xl lg:text-6xl text-center lg:text-left font-regular leading-none text-left">
                {title}
            </h1>
            <Tagline tagline={tagline} color="black" mobileCentered={true} />
            {button && <CTA text={button.text} slug={button.slug} />}
        </div>
    );
}

// Hero component
const Hero = ({ layout, title, tagline, button, media }) => {
    switch (layout) {
        case 'TaglineIllustration':
            return (
                <Section maxWidth={true} >
                    <div className="relative flex flex-col items-center lg:flex-row h-fit -mt-20 w-full pt-16">
                        <div className="flex flex-col items-center lg:w-1/2 lg:items-start py-0 gap-y-4">
                            <HeaderParagraphCTA title={title} tagline={tagline} button={button} />
                        </div>
                        <div class="relative w-full mx-auto lg:w-1/2 lg:mx-0 align-middle flex flex-col items-center text-center lg:pl-16">
                            <Animation animationData={media} />
                        </div>
                    </div>
                </Section>
            );
        case 'FullHeader':
            return (
                <Suspense fallback={<div style={{
                    minHeight: '100vh',
                    minWidth: '100vw',
                }}>Loading...</div>}>
                    <Section maxWidth={true}>
                        <div className="relative flex flex-col h-auto max-w-1400 lg:max-w-1400 w-full lg:gap-0" style={{

                        }}>
                            <div className="w-full h-full lg:w-full">
                                <div className="flex flex-col lg:items-start py-6 gap-y-4">
                                    <HeaderL1>{title}</HeaderL1>
                                </div>
                            </div>
                            {media && tagline && <div class="relative w-full mx-auto lg:w-full lg:mx-0 h-auto align-middle flex flex-col lg:flex-row h-full">
                                {media && <div class="relative w-full mx-auto lg:w-1/2 lg:mx-0 align-middle flex flex-col items-center text-center" style={{

                                }}>
                                    <Animation animationData={media} />
                                </div>}
                                <div class="relative w-full mx-auto lg:w-1/2 lg:mx-0 h-auto flex flex-col items-start justify-center text-center">
                                    <Tagline color="black" tagline={tagline} />
                                    {button && <CTA text={button.text} slug={button.slug} />}
                                </div>
                            </div>}
                        </div>
                    </Section>
                </Suspense>
            );
        case 'FullHeaderFill':
            return (
                <Suspense fallback={<div style={{
                    minHeight: '100vh',
                    minWidth: '100vw',
                }}>Loading...</div>}>
                    <Section maxWidth={true} fillScreen={false}>
                        <div className="relative flex flex-col h-auto max-w-1400 lg:max-w-1400 w-full lg:gap-0" style={{

                        }}>
                            <div className="w-full h-full lg:w-full">
                                <div className="flex flex-col lg:items-start py-6 gap-y-4">
                                    <HeaderL1>{title}</HeaderL1>
                                </div>
                            </div>
                            {media && tagline && <div class="relative w-full mx-auto lg:w-full lg:mx-0 h-auto align-middle flex flex-col lg:flex-row h-full">
                                {media && <div class="relative w-full mx-auto lg:w-1/2 lg:mx-0 align-middle flex flex-col items-center text-center" style={{

                                }}>
                                    {media &&
                                        <Animation animationData={media} />}
                                </div>}
                                <div class="relative w-full mx-auto lg:w-1/2 lg:mx-0 h-auto flex flex-col items-start justify-center text-center">
                                    <Tagline color="black" tagline={tagline} />
                                    {button && <CTA text={button.text} slug={button.slug} />}
                                </div>
                            </div>}
                        </div>
                    </Section>
                </Suspense>
            );
        // Add additional layout cases here as needed
        default:
            return null;
    }
};

export const HERO_QUERY = `
    {
        layout
        title
        tagline
        button {
            text
            slug
        }
        media {
            url
            mimeType
        }
    }
`;

export default Hero;
