import React from 'react';
import Feature from '../Feature';
import Section from '../Section';
import CTA from '../buttons/CTA';

const FeatureList = ({ preHeader, header, left, middle, right, cta }) => {
  return (
    <Section>
      <div className="text-left mb-4">
        <h3 className="text-md font-light text-gray-700 uppercase mb-2">{preHeader && preHeader}</h3>
        <p className="text-4xl font-light text-gray-800 md:max-w-2xl">{header && header}</p>
      </div>
      <div className="flex w-full items-stretch gap-8 flex-col lg:flex-row">
        {left && <div className="lg:w-1/3 w-full"><Feature {...left} /></div>}
        {middle && <div className="lg:w-1/3 w-full"><Feature {...middle} /></div>}
        {right && <div className="lg:w-1/3 w-full"><Feature {...right} /></div>}
      </div>
      {cta &&
        <div className="flex w-full justify-center"><CTA text={cta.text} slug={cta.slug} /></div>}
    </Section>
  );
};

export const FEATURELIST_QUERY = `
    {
      preHeader
      header
      left {
          title
          description
          illustration {
              url
              code
          }
      }
      middle {
          title
          description
          illustration {
              url
              code
          }
      }
      right {
          title
          description
          illustration {
              url
              code
          }
      }
      cta {
        text
        slug
      }
    }
`;

export default FeatureList;