import React from 'react';

const SectionHeader = ({ color, text }) => {
    return (
        <p className={`text-4xl text-left font-light text-gray-800 ${color === 'white' ? 'text-white' : 'text-black'}`}>
            {text}
        </p>
    );
}

export default SectionHeader;