import React, { useState, useEffect } from 'react';
import { Typewriter } from 'react-simple-typewriter';

function DynamicWord() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Check if the window width is less than or equal to 640px
    const mediaQuery = window.matchMedia('(max-width: 640px)');
    setIsMobile(mediaQuery.matches);

    // Add a listener to update the state if the window is resized
    const handleResize = () => setIsMobile(mediaQuery.matches);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="flex flex-col justify-center items-center text-center">
      <h2 className="text-3xl md:text-4xl font-regular mb-4">
        We're a rebellious group of
      </h2>
      <div className="text-4xl md:text-5xl font-bold">
        <Typewriter
          words={[
            'brainstormers',
            'color masters',
            'creative geniuses',
            'design gurus',
            'ai utilisers',
            'digital wizards',
            'idea creators',
            'innovation ninjas',
            'pixel masterminds',
            'problem solvers',
            'shape shifters',
            'style masters',
            'ux unicorns',
            'visual magicians',
            'web warriors',
            'visionares',
            'geeks',
            'perfectionists',
            'ai explorers',
          ]}
          loop={5}
          cursor
          cursorStyle="_"
          typeSpeed={70}
          deleteSpeed={50}
          delaySpeed={1000}
        />
      </div>
    </div>
  );
}

export default DynamicWord;
