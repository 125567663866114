import React, { useState, useEffect, Suspense } from 'react'
import HugeLogo from '../HugeLogo';
import Hero from '../sections/Hero';
import WhoWeAre from '../WhoWeAre';
import ThreeFeatures from '../sections/FeatureList';
import Banner from '../Banner';
import { request } from 'graphql-request'
import { Transition } from 'react-transition-group'
import AboutCarousel from './AboutCarousel';
import Example from './PageTransition';
import Slide from './Slide';
import Section from '../Section';
import ValueList from '../sections/ValueList';
import HeaderL2 from '../atoms/HeaderL2';
import Profile from './Profile';
import PortfolioCover from '../effects/PortfolioCover';
import JoinUsProfile from './JoinUsProfile';
import ProfileSpecial from './ProfileSpecial';
import MotionLogo from '../atoms/MotionLogo';

function About() {
  const [data, setData] = useState(null)
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Indicate that data is being loaded.
      const query = `
        query {
          aboutPage(where: { live: true }) {
            hero {
              title
              tagline
              button {
                text
                slug
              }
              media {
                  url
              }
            }
            values {
              ...valueFields
            }
          }
          bannerSection(where: { id: "clcby209w757m0auur2i6upmx" }) {
            banner {
              header
              paragraph
              animation {
                url
              }
            }
          }
        }

        fragment valueFields on Value {
          animation {
            url
          }
          title
          description {
            raw
            html
            markdown
            text
          }
        }
      `

      const endpoint = 'https://eu-central-1-shared-euc1-02.cdn.hygraph.com/content/clbw1lyp8485w01t78e7n5p82/master'
      const data = await request(endpoint, query)
      setData(data)
      setLoading(false); // Indicate that data has been loaded.
    }
    fetchData()
  }, [])

  console.log(data?.aboutPage.values.map(value => value.value));

  if (loading || !data) return <div className="min-h-full">Loading...</div>;

  return (
    <Suspense fallback={<div style={{
      minHeight: '100vh',
      minWidth: '100vw',
    }}>Loading...</div>}>
      <div className='px-side-padding-sm lg:px-side-padding' style={{
        width: '100%',
        backgroundColor: '#faf6eb',
        height: 'auto',
        paddingTop: '4rem',
      }}>
        <MotionLogo />
      </div>
      <div className="flex flex-col items-center" style={{

      }}>
        <Hero layout="FullHeaderFill" {...data?.aboutPage.hero} />
        {/*  <Section background="hause-beige">
          <HeaderL2 alignment="left">Our values</HeaderL2>*/ }
        {/*<Values
            values={[
              {
                animation: data.aboutPage.hero.animation,
                title: '1. Collaboration',
                description: 'The whole is usually greater than the sum of its parts. We take pride in distilling the best and brightest thoughts and ideas across everyone involved in your product, ensuring that it will truly shine in the context in which it was envisioned. We value an efficient and agile process, respecting both our own and your time dearly - in fact, we never schedule any meetings longer than 45 mins and we always plan an agenda.',
              },
              {
                animation: data.aboutPage.hero.animation,
                title: 'Title 2',
                description: 'The whole is usually greater than the sum of its parts. We take pride in distilling the best and brightest thoughts and ideas across everyone involved in your product, ensuring that it will truly shine in the context in which it was envisioned. We value an efficient and agile process, respecting both our own and your time dearly - in fact, we never schedule any meetings longer than 45 mins and we always plan an agenda.',
              },
              {
                animation: data.aboutPage.hero.animation,
                title: 'Title 3',
                description: 'The whole is usually greater than the sum of its parts. We take pride in distilling the best and brightest thoughts and ideas across everyone involved in your product, ensuring that it will truly shine in the context in which it was envisioned. We value an efficient and agile process, respecting both our own and your time dearly - in fact, we never schedule any meetings longer than 45 mins and we always plan an agenda.',
              }
            ]}
          />*/}

        <ValueList values={data?.aboutPage.values.map(value => value)} />
        <Section background="hause-dark">
          <HeaderL2 alignment="left" color="white">Meet our global team</HeaderL2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-12 mt-6">
            <Profile
              name="Yeeling Chua"
              title="Founder, UX Specialist, Design Educator & Instructional Designer"
              country="Singapore"
              imageUrl="Yeeling.JPG"
              linkedinLink="https://www.linkedin.com/in/yeelingchua/"
            />
            <Profile
              name="Anders Høedholt"
              title="AI Engineer, Creative Technologist"
              country="Denmark"
              imageUrl="https://ca.slack-edge.com/T2K0MP5E2-U02GHJTA88L-13b1f236d6fb-512"
              linkedinLink="https://www.linkedin.com/in/anders-h%C3%B8edholt-041b8a29/"
            />
            <Profile
              name="Felicia Liu"
              title="Brand Specialist, Product Strategist, Business Developer"
              country="United States of America"
              country2="Taiwan"
              imageUrl="https://ca.slack-edge.com/T2K0MP5E2-U028C9NMC3F-19645086528d-512"
              linkedinLink="https://www.linkedin.com/in/felicia-liu-867a581b/"
            />
            <Profile
              name="Martina Maitan"
              title="Design Researcher, Concept Developer"
              country2="Italy"
              country="Singapore"
              imageUrl="https://ca.slack-edge.com/T2K0MP5E2-U04A62BR6K0-987f4b165c92-512"
              linkedinLink="https://www.linkedin.com/in/martinamaitan/"
            />
            <Profile
              name="Siying Ong"
              title="Product & Visual Designer (Project Based)"
              country="Singapore"
              imageUrl="https://ca.slack-edge.com/T2K0MP5E2-U048GK3DZ3M-2895de3d90b1-512"
              linkedinLink="https://www.linkedin.com/in/ongsy/"
            />
            <Profile
              name="Vanessa Huang"
              title="Marketing Executive"
              country="Indonesia"
              country2="Taiwan"
              imageUrl="https://media.graphassets.com/03eoQh4RojNwn6wRTULA"
              linkedinLink="https://www.linkedin.com/in/vanessa-huang-570562215/"
            />
            <Profile
              name="Felix Scharfenberg"
              title="Business Strategist & Service Designer"
              country="South Korea"
              imageUrl="https://hausebucket-1.fra1.cdn.digitaloceanspaces.com/hause-studios/felix.jpg"
              linkedinLink="https://www.linkedin.com/in/felix-scharfenberg-a7618969/"
            />
            <Profile
              name="Cuthbert Ngiam"
              title="Creative Intern"
              country="Singapore"
              imageUrl="https://ca.slack-edge.com/T2K0MP5E2-U05RA0JLW8P-dd7e427cbb40-512"
              linkedinLink="https://www.linkedin.com/in/cuthbert-ngiam-6746571b2"
            />
            <Profile
              name="Angel Loh"
              title="UX Designer"
              country="Singapore"
              imageUrl="angel.jpg"
            />
            <Profile joinUs={true} />
          </div>
        </Section>
        <Banner {...data?.bannerSection.banner} />
      </div>
    </Suspense>
  );
}

export default About;