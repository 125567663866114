import React from 'react';

const TabList = ({ list }) => {
    const midpoint = Math.ceil(list.items.length / 2);
    const firstColumn = list.items.slice(0, midpoint);
    const secondColumn = list.items.slice(midpoint);

    return (
        <div className="flex flex-col items-start">
            <span className="uppercase leading-loose mb-2 tracking-widest text-regular">{list.title}</span>
            <div className="grid grid-cols-2 gap-x-10">
                <ul>
                    {firstColumn.map((point, index) => (
                        <li
                            className="flex flex-row items-center gap-x-2 text-left text-xl leading-loose"
                            key={index}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                            </svg>
                            {point}
                        </li>
                    ))}
                </ul>
                <ul>
                    {secondColumn.map((point, index) => (
                        <li
                            className="flex flex-row items-center gap-x-2 text-left text-xl leading-loose"
                            key={index}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                            </svg>
                            {point}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default TabList;