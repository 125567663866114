import React from 'react';
import DynamicWord from '../DynamicWord';
import Section from '../Section';
import CTA from '../buttons/CTA';

const Tagline = ({ preHeader, tagline, words }) => {
    return (
        <div className="w-full p-side-padding-sm py-12 md:py-section-y-padding md:px-side-padding bg-hause-beige flex flex-col justify-center items-center content-center max-w-screen text-center gap-y-10">
            <h1 className="font-regular tracking-widest text-regular uppercase">Who We Are</h1>
            <div className="flex flex-col gap-1">
                <DynamicWord />
                <p className="text-xl mt-4 font-regular">And the world is our playground</p>
            </div>
            <CTA text="More about us" slug="about" />
        </div>
    );
};

export const TAGLINE_QUERY = `
{
    preHeader
    tagline
    words
}
`;

export default Tagline;