import React from 'react';

const Placeholder = () => (
  <div style={{
    width: '100%',
    height: '1200px',
    background: '#eee',
  }} />
);

export default Placeholder;