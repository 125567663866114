import React, { useState } from 'react';
import Modal from 'react-modal';
import Section from './Section';
import HeaderL2 from './atoms/HeaderL2';
import Tagline from './atoms/Tagline';
import Animation from './Animation';
import PerspectiveHover from './effects/PerspectiveHover';
import { X } from 'react-feather';
import ExternalLinkModal from './molecules/ExternalLinkModal';

const Banner = ({
    header,
    paragraph,
    buttonText,
    slug,
    animation,
    externalUrl = "https://tally.so/r/wM1yv8",
    modalSize = "small",
    openInNewTab = false
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleClick = () => {
        if (openInNewTab) {
            window.open(externalUrl, "_blank");
        } else {
            setIsModalOpen(true);
        }
    };

    return (
        <Section>
            <PerspectiveHover>
                <div onClick={handleClick} className="flex overflow-hidden flex-col md:flex-row bg-hause-coral saturate-100 rounded-3xl p-6 md:p-12 select-none hover:opacity-90 transition-opacity">
                    <div className="md:w-2/3 md:px-12 md:py-12 text-left">
                        <div className="max-w-2xl">
                            <HeaderL2 color="white">
                                <React.Fragment>
                                    {header.split('\n').map((text, index) => (
                                        <React.Fragment key={index}>
                                            {text}
                                            <br />
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            </HeaderL2>
                            <Tagline tagline={paragraph} color="white" />

                            {buttonText &&
                                <button className="rounded-full bg-white px-6 py-3 flex items-center justify-between">
                                    {buttonText}
                                    <svg className="h-4 w-4 fill-current text-hause-coral" viewBox="0 0 20 20">
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                </button>
                            }
                        </div>
                    </div>
                    <div className="md:w-1/3 md:px-0 md:py-0 h-auto flex items-center justify-center">
                        <div className="w-48">
                            <Animation animationData={animation} />
                        </div>
                    </div>
                </div>
            </PerspectiveHover>
            {!openInNewTab && (
                <ExternalLinkModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    url={externalUrl}
                    size={modalSize}
                />
            )}
        </Section>
    );
};

export const BANNER_QUERY = `
    {
        header
        paragraph
        button {
            text
            slug
        }
        animation {
            url
        }
    }
`;

export default Banner;