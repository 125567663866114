import React from 'react';
import Animation from '../Animation';
import HeaderL3 from '../atoms/HeaderL3';
import Paragraph from '../atoms/Paragraph';

const Value = ({ animation, title, description }) => {
    return (
        <div className="flex flex-col gap-4 text-left items-start">
            <Animation animationData={animation} />
            <div className="flex items-center gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-7 h-7">
                    <path fill-rule="evenodd" d="M5 10a.75.75 0 01.75-.75h6.638L10.23 7.29a.75.75 0 111.04-1.08l3.5 3.25a.75.75 0 010 1.08l-3.5 3.25a.75.75 0 11-1.04-1.08l2.158-1.96H5.75A.75.75 0 015 10z" clip-rule="evenodd" />
                </svg>
                <HeaderL3>{title}</HeaderL3>
            </div>
            <Paragraph><div dangerouslySetInnerHTML={{ __html: description.html }} /></Paragraph>
        </div>
    );
};

export default Value;