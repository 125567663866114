import React from 'react';
import { useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

const CTA = ({ text, slug }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (event) => {
    event.preventDefault();
    navigate(`/${slug}`);
  }
  return (
    <a href={`/${slug}`} className="text-white uppercase py-3 px-5 rounded-full flex items-center justify-between gap-x-2 saturate-100 font-[600] text-white/90 text-sm tracking-[0.11em] border border-hause-coral bg-hause-coral hover:bg-hause-coral/10 hover:text-hause-coral active:scale-95 transition-all duration-200">
      {text}
      <svg width="18" height="19" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.66663 7.74951C2.25241 7.74951 1.91663 8.0853 1.91663 8.49951C1.91663 8.91373 2.25241 9.24951 2.66663 9.24951L2.66663 7.74951ZM14.5303 9.02984C14.8232 8.73695 14.8232 8.26207 14.5303 7.96918L9.75732 3.19621C9.46442 2.90332 8.98955 2.90332 8.69666 3.19621C8.40376 3.4891 8.40376 3.96398 8.69666 4.25687L12.9393 8.49951L8.69666 12.7422C8.40377 13.035 8.40377 13.5099 8.69666 13.8028C8.98955 14.0957 9.46443 14.0957 9.75732 13.8028L14.5303 9.02984ZM2.66663 9.24951L14 9.24951L14 7.74951L2.66663 7.74951L2.66663 9.24951Z" fill="currentColor" />
      </svg>
    </a>
  );
}

export default CTA;