import React, { useEffect, useRef, useState } from 'react';

function BackgroundVideo({ item, isExpanded, scrollingDivRef }) {
    const [scale, setScale] = useState(1);

    const containerRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = scrollingDivRef.current.scrollTop;

            if (scrollPosition <= 0) {
                setScale(1);
            } else if (scrollPosition <= 600) {
                setScale(1 - 0.4 * (scrollPosition / 600));
            } else {
                setScale(0.6);
            }
        };

        scrollingDivRef.current.addEventListener('scroll', handleScroll);

        return () => {
            if (scrollingDivRef.current) {
                scrollingDivRef.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, [isExpanded]);

    return (
        <div
            className="relative w-full h-full"
            style={{
                transitionDuration: isExpanded ? '0.1s' : '',
                transitionTimingFunction: isExpanded ? 'ease' : '',
                position: isExpanded ? 'relative' : 'absolute',
                height: isExpanded ? '100%' : '100%',
                width: isExpanded ? '100%' : '100%',
                maxWidth: isExpanded ? '2000px' : '',
                marginLeft: isExpanded ? '100px' : '0px',
                marginRight: isExpanded ? '100px' : '0px',
                overflow: 'hidden',
                borderTopLeftRadius: !isExpanded ? '0.3rem' : '1.1rem',
                borderBottomLeftRadius: !isExpanded ? '0.3rem' : '1.1rem',
                borderTopRightRadius: !isExpanded ? '0rem' : '1.1rem',
                borderBottomRightRadius: !isExpanded ? '0rem' : '1.1rem',
                height: isExpanded ? '100vh' : '',
                zIndex: 50,
                transform: `scale(${scale})`,
            }}
            ref={containerRef}
        >
            {item.video.url &&
                <video
                    className="w-full h-full object-cover"
                    autoPlay
                    loop
                    muted
                    //poster={item.screen.url}
                    src={item.video.url}
                />}

            <div>
                <div
                    className="rounded-tl-lg rounded-bl-lg"
                    style={{
                        position: 'absolute',
                        opacity: !isExpanded ? '1' : '0',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.3)',
                    }}
                />
            </div>
        </div>
    );
}

export default BackgroundVideo;
