import React from 'react';

const HeaderL2 = ({ color, alignment, children }) => {
  return (
    <h2
      className={`text-6xl leading-tight whitespace-pre-line font-light mb-2 ${color === 'white' ? 'text-white' : 'text-black'} text-${alignment}`}
    >
      {children}
    </h2>
  );
}

export default HeaderL2;