import React from 'react';
import { ReactSVG } from 'react-svg';

function SourceSVG(props) {
    const { data } = props;
    return (
        <ReactSVG src={`data:image/svg+xml;utf8,${encodeURIComponent(data)}`} />
    );
}

export default SourceSVG;