import React from 'react';

const Section = ({ children, background, maxWidth = true, minHeight = false, padding = true, integrated = false, fillScreen = false, transparent = false }) => {
    return (
        <section
            className={`flex justify-center bg-hause-bg px-side-padding-sm lg:px-side-padding ${minHeight ? 'min-h-screen' : ''} ${padding ? 'py-section-y-padding' : ''} w-full }`}
            style={{
                minHeight: fillScreen ? '100vh' : 'auto',
                backgroundColor: transparent ? 'transparent' : background === 'hause-beige' ? '#fae9db' : background === 'hause-dark' ? '#242222' : '#faf6eb',
            }}
        >
            {maxWidth ? (
                <div className="max-w-1400 mx-auto flex flex-col gap-9 flex-grow">
                    {children}
                </div>
            ) : (
                <>{children}</>
            )}
        </section>
    );
};

export default Section;