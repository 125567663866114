import React from 'react';

const SimpleTable = ({ data }) => {
    return (
        <div className="w-full divide-y divide-white divide-opacity-50">
            {data.map((row, i) => (
                <div key={i} className="flex py-2.5 border-white border-opacity-10 ">
                    <div className="text-xs font-regular tracking-wider uppercase text-white w-1/4 text-left leading-regular">
                        {row[0]}
                    </div>
                    <div className="text-sm font-medium text-white w-3/4 text-right leading-none">
                        {row[1]}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default SimpleTable;