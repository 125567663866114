import React from 'react';
import { useNavigate } from 'react-router-dom';

const CardControl = (props) => {
    const navigate = useNavigate();
    return (
        <div className="flex items-center content-center h-full">
            <button
                className="text-hause-coral border-none border-hause-coral rounded-md px-2 py-0.5 hover:hause-coral-dark transition duration-200"
                onClick={() => {
                    navigate(`/work`);
                    props.handleCloseButtonClick();
                }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
                </svg>
            </button>
            {/*<button
                className="text-hause-coral border-none border-hause-coral rounded-md px-2 py-0.5 hover:hause-coral-dark transition duration-200"
                onClick={() => props.handleCloseButtonClick()}
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 6.878V6a2.25 2.25 0 012.25-2.25h7.5A2.25 2.25 0 0118 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 004.5 9v.878m13.5-3A2.25 2.25 0 0119.5 9v.878m0 0a2.246 2.246 0 00-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0121 12v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6c0-.98.626-1.813 1.5-2.122" />
                </svg>
            </button>*/}
        </div>
    );
}

export default CardControl;