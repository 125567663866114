import React from 'react';

const CollectionAsset = () => {
    return null;
};

export const COLLECTIONASSET_QUERY = `
    {
        tags
        code
    }
`;

export default CollectionAsset;