import { motion } from "framer-motion";

export default function MotionLogoWhite() {
    return (
        <div
            className="stroke-hause-coral"
            style={{
                border: "none",
                padding: "0",
                marginLeft: "auto",
                marginRight: "auto",
                filter: "contrast(2)",
            }}
        >
            <motion.svg
                className="w-6"
                viewBox="0 0 28 48"
                xmlns="http://www.w3.org/2000/svg"
            >
                <motion.path
                    initial={{ pathLength: 0, fill: "#ffffff", fillOpacity: 0 }}
                    animate={{ pathLength: [0, 1, 1, 1, 0], fill: ["#ffffff", "#fe7348", "#ffffff", "#ffffff"], fillOpacity: [0.1, 1, 1, 0.1] }}
                    transition={{
                        duration: 7,
                        ease: "easeInOut",
                        repeat: Infinity,
                        yoyo: Infinity,
                        repeatType: "loop",
                        repeatDelay: 3.0,
                        fillOpacity: {
                            duration: 8,
                            repeatDelay: 0.5,
                            delay: 0,
                            repeat: Infinity,
                            repeatType: "loop",
                            delay: 2
                        }
                    }}
                    strokeWidth={0.5}
                    strokeDasharray="0 1"
                    d="M6.94401 7.98413L0 11.9841V44.0162L6.91201 48.0002L6.94401 15.9841L13.872 11.9841L6.94401 7.98413Z"
                />
                <motion.path
                    initial={{ pathLength: 0, fill: "#ffffff", fillOpacity: 0 }}
                    animate={{ pathLength: [0, 1, 1, 1, 0], fill: ["#ffffff", "#fe7348", "#ffffff", "#ffffff"], fillOpacity: [0.1, 1, 1, 0.1] }}
                    transition={{
                        duration: 3,
                        ease: "easeInOut",
                        repeat: Infinity,
                        yoyo: Infinity,
                        repeatType: "loop",
                        repeatDelay: 4.5,
                        fillOpacity: {
                            duration: 8,
                            repeatDelay: 6.5,
                            delay: 0,
                            repeat: Infinity,
                            repeatType: "loop",
                            delay: 2
                        }
                    }}
                    strokeWidth={0.5}
                    strokeDasharray="0 1"
                    d="M20.8003 0L13.8723 4V23.984L20.8003 19.984V8.00001L27.7443 4L20.8003 0Z"
                />
                <motion.path
                    initial={{ pathLength: 0, fill: "#ffffff", fillOpacity: 0 }}
                    animate={{ pathLength: [0, 1, 1, 1, 0], fill: ["#ffffff", "#fe7348", "#fe7348", "#ffffff"], fillOpacity: [0.15, 1, 1, 0.15] }}
                    transition={{
                        duration: 3,
                        ease: "easeInOut",
                        repeat: Infinity,
                        yoyo: Infinity,
                        repeatType: "loop",
                        repeatDelay: 3.5,
                        fillOpacity: {
                            duration: 8,
                            repeatDelay: 3.5,
                            delay: 0,
                            repeat: Infinity,
                            repeatType: "loop",
                            delay: 2
                        }
                    }}
                    strokeWidth={0.5}
                    strokeDasharray="0 1"
                    d="M13.8723 32L20.8003 28V40L13.8723 36V32Z"
                />
            </motion.svg>
        </div>
    );
}