import React from 'react';

const Tagline = ({ tagline, color, mobileCentered }) => {
    return (
        <p className={`text-md lg:text-2xl text-left md:text-left lg:text-left font-regular py-4 text-left lg:leading-8 leading-8 ${color === 'black' ? 'text-black' : 'text-white'} ${mobileCentered ? 'text-center' : ''}`}>
            {tagline}
        </p>
    );
}

export default Tagline;
