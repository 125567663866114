import React, { useState, useRef, useEffect, useContext } from 'react';
import MenuItem from './MenuItem';
import { CSSTransition } from 'react-transition-group';
import MotionLogoWhite from './atoms/MotionLogoWhite';
import { request } from 'graphql-request';
import CardControl from './navigation/CardControl';
import { AppContext } from '../App';
import ExternalLinkModal from './molecules/ExternalLinkModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import useBreakpoint from './useBreakpoint';

const NAV_QUERY = `
  query {
    pages(where: {navigation: main}) {
      title
      slug
      order
    }
  }
`;

const Navbar = (props) => {
  const { expandedCard, setExpandedCard } = useContext(AppContext);
  const [bgColor, setBgColor] = useState('hause-bg');
  const [menuItems, setMenuItems] = useState([]);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  menuItems.sort((a, b) => a.order - b.order);

  const [isTallyOpen, setIsTallyOpen] = useState(false);
  const tallyUrl = "https://tally.so/r/wM1yv8";
  //const kickstartIcon = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" /></svg>`;
  const kickstartIcon = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="w-5 h-5">
  <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
</svg>`

  const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);
  const calendarIcon = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" /></svg>
`;
  const calendlyUrl = "https://calendly.com/hellohause/30min";
  const navigate = useNavigate();

  const handleMenuItemClick = (item) => {
    props.changeLayout(item.slug);
    setIsMobileMenuOpen(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  }


  const breakpoints = {
    xs: 0,
    sm: 768,
    md: 992,
    lg: 1200,
    xl: 1600,
  };

  const mainContainerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(null);

  useEffect(() => {
    const updateContainerWidth = () => {
      if (mainContainerRef.current) {
        setContainerWidth(mainContainerRef.current.clientWidth);
      }
    };

    updateContainerWidth();

    const resizeObserver = new ResizeObserver(updateContainerWidth);
    if (mainContainerRef.current) {
      resizeObserver.observe(mainContainerRef.current);
    }

    window.addEventListener('resize', updateContainerWidth);

    return () => {
      if (mainContainerRef.current) {
        resizeObserver.unobserve(mainContainerRef.current);
      }
      window.removeEventListener('resize', updateContainerWidth);
    };
  }, []);

  const breakpoint = useBreakpoint(
    breakpoints,
    containerWidth
  );





  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsMobileMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.current]);

  console.log("EXPANDED CARD");
  console.log(expandedCard);

  useEffect(() => {
    const fetchMenuItems = async () => {
      const data = await request('https://eu-central-1-shared-euc1-02.cdn.hygraph.com/content/clbw1lyp8485w01t78e7n5p82/master', NAV_QUERY);
      setMenuItems(data.pages);
    };
    fetchMenuItems();
  }, []);

  useEffect(() => {
    const onScroll = () => {
      if (props.logoText) {
        setBgColor('black');
      } else {
        setBgColor('transparent');
      }
    }
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [props.logoText]);

  return (
    <nav id="navbar"
      className={`fixed flex content-between justify-center border-${bgColor} backdrop-blur-xl bg-hause-bg bg-opacity-90 saturate-100`}
      style={{
        zIndex: 999,
        width: breakpoint === 'xs' || breakpoint === 'sm' || breakpoint === 'md' ? '100%' : 'auto',
        maxWidth: '100%',
        bottom: breakpoint === 'xs' || breakpoint === 'sm' || breakpoint === 'md' ? '0px' : '20px',
        borderRadius: breakpoint === 'xs' || breakpoint === 'sm' || breakpoint === 'md' ? '0px' : '10px',
        backgroundColor: 'rgb(36, 34, 34, 0.8)',
        color: 'white',
        transition: 'all 0.2s ease-in-out',
        paddingLeft: breakpoint === 'xs' || breakpoint === 'sm' || breakpoint === 'md' ? '20px' : '30px',
        paddingRight: breakpoint === 'xs' || breakpoint === 'sm' || breakpoint === 'md' ? '20px' : '30px',
        border: '1px solid rgba(255,255,255,0.2)',
      }}>
      <div className="flex items-center w-full justify-between max-w-1400">
        <div className={`flex gap-3 py-4 items-center ${expandedCard.active ? '' : ''}`} style={{
          paddingRight: breakpoint === 'xs' || breakpoint === 'sm' || breakpoint === 'md' ? '0px' : '30px',
          borderRight: breakpoint === 'xs' || breakpoint === 'sm' || breakpoint === 'md' ? 'none' : '1px solid rgba(255,255,255,0.2)',
        }}>
          {expandedCard.active && <div>
            <CSSTransition
              in={expandedCard.active}
              timeout={200}
              classNames="fade"
              unmountOnExit
              appear
            >
              <CardControl handleCloseButtonClick={() => setExpandedCard(false, null)} />
            </CSSTransition>
          </div>}
          <a
            href="/hello"
            className={`flex transition-all ease-in-out duration-200 items-center gap-2 ${expandedCard.active ? 'delay-0 pl-6 translate-x-1' : 'delay-200 pl-0 translate-x-0'}`}
          >
            <MotionLogoWhite />
            {props.current == "hello-old" ?
              <CSSTransition
                in={props.logoText}
                timeout={200}
                classNames="fade"
                unmountOnExit
                appear
              >
                <div className="flex text-white text-left ml-0.5 font-bold text-2xl leading-none">
                  Hause
                  <br />
                  Studios
                </div>
              </CSSTransition>
              :
              <div className="flex text-left ml-0.5 font-bold text-xl leading-none" style={{
                color: 'rgba(255,255,255,0.9)',
                display: breakpoint === 'xs' || breakpoint === 'sm' || breakpoint === 'md' ? 'none' : 'block',
              }}>
                Hause
                <br />
                Studios
              </div>
            }
          </a>
        </div>
        <div className="flex-shrink-0">

        </div>

        {/* Menu items */}
        <div className="block w-full">
          <div className="flex flex-row w-full ml-4 gap-0 -mr-2" style={{
            justifyContent: 'space-between',
          }}>
            {menuItems.map(item => (
              (item.title != "Start" &&
                <MenuItem current={props.current == item.slug} slug={item.slug} onClick={() => handleMenuItemClick(item)}>
                  {item.title}
                </MenuItem>)
            ))}
            {/*<MenuItem onModalOpen={() => setIsCalendlyOpen(true)}>
              Book a meeting
            </MenuItem>*/}
            <MenuItem onModalOpen={() => setIsTallyOpen(true)}>
              Contact
            </MenuItem>
            {/*<MenuItem onModalOpen={() => setIsCalendlyOpen(true)} variant="grouped-feature-r" icon={calendarIcon}>
              Schedule call
          </MenuItem>*/}
            <ExternalLinkModal
              isOpen={isTallyOpen}
              onClose={() => setIsTallyOpen(false)}
              url={tallyUrl}
            />
            <ExternalLinkModal
              isOpen={isCalendlyOpen}
              onClose={() => setIsCalendlyOpen(false)}
              url={calendlyUrl}
            />
          </div>
        </div>


        <button onClick={toggleMobileMenu} className={`hidden`}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 9h16.5m-16.5 6.75h16.5" />
          </svg>
        </button>

        <div className={`fixed top-navbar left-0 right-0 bottom-0 -mt-4 h-screen py-0 pb-16 backdrop-blur-xl rounded-b-lg ${isMobileMenuOpen ? 'block' : 'hidden'}`}>
          <div className="relative w-full h-full bg-hause-bg bg-opacity-100 flex flex-col justify-between" style={{
            position: 'relative',
            backdropFilter: 'blur(20px)',
          }}>
            <div className="flex flex-col">
              {menuItems.map((item, index) => (
                <MenuItem current={props.current == item.slug} slug={item.slug} onClick={() => handleMenuItemClick(item)}>
                  {item.title}
                </MenuItem>
              ))}
            </div>
            <div className="flex flex-row items-center justify-center mb-20">
              <MenuItem onModalOpen={() => setIsTallyOpen(true)} variant="grouped-feature-l" icon={kickstartIcon}>
                Write to us
              </MenuItem>
              <MenuItem onModalOpen={() => setIsCalendlyOpen(true)} variant="grouped-feature-r" icon={calendarIcon}>
                Schedule call
              </MenuItem>
            </div>
          </div>
        </div>
      </div>
    </nav >
  );
}

export default Navbar;