import React, { useState, useEffect } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import DynamicTitle from './DynamicTitle'

// Any suggestion on how to make the animation cooler / smoother?
// I'm thinking of using a 3D perspective, but I'm not sure how to do that with framer-motion.

const DynamicCover = ({ elements, minRepetitions, maxRepetitions }) => {
    const [isVisible, setIsVisible] = useState(true);
    const [repeatedElements, setRepeatedElements] = useState([]);
    const variants = {
        hidden: {
            //x: -1000,
            opacity: 0
        },
        visible: {
            //x: 0,
            opacity: 1
        }
    }

    useEffect(() => {
        let newRepeatedElements = [];
        elements.forEach(element => {
            const parsedElement = <div dangerouslySetInnerHTML={{ __html: element }} />;
            const numberOfRepetitions = Math.floor(Math.random() * (maxRepetitions - minRepetitions + 1) + minRepetitions);
            const repeatedElements = [...Array(numberOfRepetitions)].map(() => parsedElement);
            newRepeatedElements = [...newRepeatedElements, ...repeatedElements];
        });
        setRepeatedElements(newRepeatedElements);
    }, [elements, minRepetitions, maxRepetitions])

    return (
        <AnimatePresence>
            {isVisible && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate="visible"
                    exit={{ opacity: 0 }}
                    style={{
                        width: "40%",
                        height: "160%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        //background: "#212121",
                        position: "absolute",
                        left: "0%",
                        opacity: 0,
                        zIndex: 0,
                        //transform: `perspective: ${Math.random() * 100}px`,
                        //transformStyle: "preserve-3d"
                    }}
                    variants={variants}
                >
                    <div className="relative flex flex-col-reverse items-end h-full w-full saturate-100 skew-y-0 overflow-visible">
                        {repeatedElements.map((element, index) => (
                            <motion.div
                                key={index}
                                style={{
                                    position: "absolute",
                                    top: `${Math.random() * 100}%`,
                                    left: `${Math.random() * 100}%`,
                                    opacity: 0,
                                    filter: "blur(20px)",
                                    //transform: `perspective: ${Math.random() * 100}px`,
                                    //transformStyle: "preserve-3d"
                                }}
                                initial={{ opacity: 0.3, filter: "blur(10px)" }}
                                exit={{ opacity: 0.3, filter: "blur(10px)" }}
                                animate={{
                                    x: [100, -100, 100],
                                    y: [-100, 100, -100],
                                    //rotateY: [0, 360, 0],
                                    //scale: [1, 0.75, 1],
                                    scale: [`${Math.random() * (0.25 - 0.5 + 0.5) + 0.5}`, `${Math.random() * (0.5 - 0.3 + 0.3) + 0.3}`, `${Math.random() * (0.5 - 0.3 + 0.3) + 0.3}`],
                                    z: [200, 100, 200],
                                    opacity: [0.2, 0.3, 0.5, 0.6, 0.5, 0.4, 0.4, 0.3, 0.3, 0.2, 0.2],
                                    filter: ["blur(30px)", "blur(5px)", "blur(5px)", "blur(30px)", "blur(30px)"],
                                }}
                                transition={{
                                    ease: "easeInOut",
                                    repeat: Infinity,
                                    duration: 30 + Math.random() * 50,
                                    delay: 0,
                                    //opacity: { duration: 500, ease: "easeInOut" },
                                }}
                            >
                                {element}
                            </motion.div>
                        ))}
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    )
}

export default DynamicCover;