import React from 'react';
import Tagline from '../atoms/Tagline';
import BuildSection from '../utils/BuildSection';

const Slide = ({ preHeader, header, primary }) => {
    return (
        <div className="flex h-full">
            <div className="w-full md:w-full flex flex-col items-start justify-between sm:pl-side-padding-sm md:pl-side-padding-md lg:pl-side-padding py-section-y-padding space-y-24 h-full">
                <div></div>
                <div className="w-full">
                    <div className='text-left flex flex-col gap-y-4 w-full'>
                        <h2 className="text-xl lg:text-2xl font-medium text-white lg:p-0 lg:text-left text-center w-full">{preHeader}</h2>
                        <h1 className="lg:text-9xl text-7xl font-light text-white text-left lg:p-0 lg:text-left text-center w-full">
                            {header}
                        </h1>
                    </div>
                </div>
                <div className="text-white lg:px-0 px-12 text-center"><BuildSection section={primary} /></div>
            </div>
        </div>
    );
}

export default Slide;