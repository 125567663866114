import React from 'react';
import Profile from '../molecules/Profile';
import Section from '../Section';
import HeaderL2 from '../atoms/HeaderL2';

const ProfileList = ({ header, profiles }) => {
    return (
        <Section background="hause-dark">
            <HeaderL2 alignment="left" color="white">{header}</HeaderL2>
            <div className="grid grid-cols-4 gap-12 mt-6">
                {profiles.map(profile => (
                    <Profile
                        name={profile.name}
                        title={profile.title}
                        country={profile.country}
                        imageUrl={profile.image && profile.image.urll}
                        twitterLink={profile.twitter}
                        linkedinLink={profile.linkedin}
                    />
                ))}
            </div>
        </Section>
    );
};

export const PROFILELIST_QUERY = `
    {
        header
        profiles {
            name
            areas
            country
            image {
                url
            }
            twitter 
            linkedin
        }
    }
`;

export default ProfileList;