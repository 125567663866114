import React from 'react';
import Section from '../Section';
import SectionHeader from '../atoms/SectionHeader';

const Quote = ({ text, color = "black" }) => {
    return (
        <Section maxWidth={true} minHeight={false}>
            <div className="max-w-3/5">
                <SectionHeader color={color && color} text={text && text} />
            </div>
        </Section>
    );
};

export const QUOTE_QUERY = `
    {
        text
    }
`;

export default Quote;