import React from 'react';

class ToggleOverflow extends React.Component {
    componentDidUpdate(prevProps) {
        if (prevProps.behavior !== this.props.behavior) {
            this.toggleBodyOverflow(this.props.behavior);
        }
    }

    toggleBodyOverflow = (behavior) => {
        document.body.classList.remove('overflow-scroll');
        document.body.classList.remove('overflow-hidden');
        document.body.classList.add(`overflow-${behavior}`);
        console.log("updated behavior");
    }

    render() {
        return null;
    }
}

export default ToggleOverflow;