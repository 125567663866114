import React from 'react';

const HeaderL1 = ({ children }) => {
    return (
        <h1 className="text-4xl lg:text-6xl text-center lg:text-left font-regular leading-tight text-left">
            {children}
        </h1>
    );
}

export default HeaderL1;