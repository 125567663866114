import React, { useState, useEffect } from 'react';
import { useGesture } from 'react-use-gesture';
import { motion } from 'framer-motion';
import { request } from 'graphql-request';
import MotionLogo from './atoms/MotionLogo';
import MenuItem from './MenuItem';
import { CSSTransition } from 'react-transition-group';

const NAV_QUERY = `
  query {
    pages(where: {navigation: main}) {
      title
      slug
    }
  }
`;

const SwipeableNavbar = (props) => {
    const [bgColor, setBgColor] = useState('hause-bg');
    const [menuItems, setMenuItems] = useState([]);

    useEffect(() => {
        const fetchMenuItems = async () => {
            const data = await request('https://eu-central-1-shared-euc1-02.cdn.hygraph.com/content/clbw1lyp8485w01t78e7n5p82/master', NAV_QUERY);
            setMenuItems(data.pages);
        };
        fetchMenuItems();
    }, []);

    useEffect(() => {
        const onScroll = () => {
            if (props.logoText) {
                setBgColor('black');
            } else {
                setBgColor('transparent');
            }
        }
        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, [props.logoText]);

    // Initialize the current position state to 0
    const [pos, setPos] = useState(0);

    // Bind the swipe gestures to the component
    const bind = useGesture({
        onSwipe: ({ direction: [xDir] }) => {
            // Update the position based on the swipe direction
            if (xDir > 0) {
                setPos(pos - 1);
            } else {
                setPos(pos + 1);
            }
        },
    });

    return (
        <nav id="navbar" className="fixed top-0 w-full z-50 px-side-padding-sm md:px-side-padding py-4 border-hause-bg backdrop-blur-xl bg-hause-bg bg-opacity-90 saturate-100">
            <div className="flex-shrink-0">
                {/* Add the bind object to the wrapper element to bind the swipe gestures */}
                <div {...bind()} className="relative">
                    {/* Use the transform style to enable horizontal swiping */}
                    <motion.div
                        className="overflow-hidden"
                        style={{
                            transform: `translateX(${pos * 100}%)`,
                        }}
                    >
                        {/* Render the menu items as a horizontal list */}
                        {menuItems.map((item) => (
                            <MenuItem
                                className="pointer-events-none"
                                onClick={() => props.changeLayout(item.slug)}
                            >
                                <div className={`${props.current == item.slug ? 'font-bold' : ''}`}>
                                    {item.title}
                                </div>
                            </MenuItem>
                        ))}
                    </motion.div>
                    {/* Use the ::before and ::after pseudo-elements to create the fading effect on the sides */}
                    <motion.div
                        className="absolute inset-0 z-40 bg-hause-bg bg-opacity-20"
                        style={{
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                            background: `linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%)`,
                        }}
                        initial
                        animate={{
                            opacity: pos === 0 ? 0 : 1,
                        }}
                        transition={{
                            duration: 0.2,
                        }}
                    />
                    <motion.div
                        className="absolute inset-0 z-40 bg-hause-bg bg-opacity-20"
                        style={{
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                            background: `linear-gradient(to left, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%)`,
                        }}
                        initial
                        animate={{
                            opacity: pos === menuItems.length - 1 ? 0 : 1,
                        }}
                        transition={{
                            duration: 0.2,
                        }}
                    />
                </div>
            </div>
        </nav >
    );
};

export default SwipeableNavbar;
