import React from 'react';
import BigLogo from '../molecules/BigLogo';
import Section from '../Section';

const HauseHeader = ({ text }) => {
    return (
        <Section maxWidth={true} padding={false}>
            <div className="flex flex-grow"><BigLogo text={text} /></div>
        </Section>
    );
};

export const HAUSEHEADER_QUERY = `
    {
        text
    }
`;


export default HauseHeader;