import React, { useState, useEffect } from 'react';
import TabHeader from './TabHeader';
import TabContent from './TabContent';
import TabList from './TabList';
import TabIcon from './TabIcon';
import { motion } from "framer-motion";

const Tabbar = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [openTab, setOpenTab] = useState(null);
    const [showOverlay, setShowOverlay] = useState(false);

    const handleOverlayClose = () => {
        setShowOverlay(false);
    };

    const tabs = [
        {
            pre: '01',
            icon: 'https://media.discordapp.net/attachments/1064586759490519143/1083594629674434591/andershoedholt_3d_scene_depicting_driven_employees_helping_a_cl_0730eecb-dca5-49a0-8e7d-d777a52abd5b.png?width=1246&height=1246',
            title: 'Creative Consulting',
            content: `Creative consulting is the secret ingredient for creating a user-centered approach that drives business success. We work with you to understand your business needs and provide expert advice and guidance to help you make informed decisions about your digital strategy, design, and development. We'll help you create products and services that are easy to use, meet your customers' needs and exceed their expectations.`,
            list:
            {
                title: 'What this could include',
                items: ['Brand Strategy', 'Product Design', 'Marketing Strategy', 'Business Innovation Audit', 'Workshops', 'User Experience & Usability Audit'],
            },
        },
        {
            pre: '02',
            icon: 'https://media.discordapp.net/attachments/1064586759490519143/1083600300381851699/andershoedholt_3d_scene_depicting_driven_employees_helping_a_cl_f15302f3-e5ae-42e7-a9a6-a4c78640e54b.png?width=1246&height=1246',
            title: 'Design & User Research',
            content: `Research is the backbone of creating successful products and services that meet customers' needs and preferences. Our design and user research services help businesses identify opportunities and potential roadblocks, so they can create a personalized and user-friendly experience that leads to increased customer satisfaction and business success. Our team of experts will work with you to understand your customers' needs and develop a research plan that will help you make informed decisions about your product or service.`,
            list:
            {
                title: 'What this could include',
                items: ['Customer insights', 'User personas', 'User journey map', 'User flow diagram', 'Competitive analysis', 'Recommendations', 'Usability testing report', 'Analytics report'],
            }
        },
        {
            pre: '03',
            icon: 'https://media.discordapp.net/attachments/1064586759490519143/1083674189166297088/andershoedholt_3d_scene_depicting_driven_employees_helping_a_cl_58f18784-2fd2-4f6a-98c5-3b7876492c53.png?width=1246&height=1246',
            title: 'Strategy & Innovation',
            content: `Innovation is key to staying ahead in today's fast-paced business environment. Our strategy and innovation services help businesses identify new opportunities, develop new products and services, and stay ahead of the curve. Whether you need help with market research, product development, or business strategy, our team of experts will work with you to understand your goals and develop a plan to achieve them. We specialize in helping businesses stay competitive and relevant in today's ever-changing market.`,
            list:
            {
                title: 'What this could include',
                items: ['Customer insights', 'User personas', 'User journey map', 'User flow diagram', 'Competitive analysis', 'Recommendations', 'Usability testing report', 'Analytics report'],
            }
        },
        {
            pre: '04',
            icon: 'https://media.discordapp.net/attachments/1064586759490519143/1083677502968430702/andershoedholt_3d_scene_depicting_driven_employees_helping_a_cl_652245b2-52b8-4920-8b35-68aea897495d.png?width=1246&height=1246',
            title: 'Brand & Product Design',
            content: `Brand design and product design are two essential processes that work together to create an overall visual identity and user experience for a company, product or service. Brand design involves creating a visual identity that aligns with the business goals and target market, such as a logo, color palette, typography, imagery and other elements that make up a brand's visual language.
            
            Product design, on the other hand, encompasses everything from industrial design, prototyping, to user interface and experience design, which ensures the product or service is meeting the customers' needs and preferences, is user-friendly and visually pleasing, leading to increased customer satisfaction, retention and revenue. Together, these processes help businesses build trust with their target market, communicate the value of their product or service in a clear and consistent way, and increase customer satisfaction, retention and revenue.`,
            list:
            {
                title: 'What this could include',
                items: ['Creative Workshops', 'Brand guidelines', 'Brand identity (logo, colors, typography)', 'Packaging Design', 'Name card Design', 'Product prototype', 'User interface design', 'User experience design', 'Design Systems'],
            }
        },
        {
            pre: '05',
            icon: 'https://media.discordapp.net/attachments/1064586759490519143/1083683616376229969/andershoedholt_3d_scene_depicting_driven_employees_helping_a_cl_c5e1224e-75a5-4fb2-80e6-c3325efa8f5c.png?width=1246&height=1246',
            title: 'Technical Development',
            content: `Technical development is the process of building and implementing the final product or service, based on the designs and plans created in earlier phases. It's about bringing the vision to life and making sure it works seamlessly for the end-user. Our developers use the latest in web development and programming languages to create high-performing, stable, and scalable digital products that meet your business needs and exceed your customers' expectations.`,
            list:
            {
                title: 'What this could include',
                items: ['Front-end development', 'Back-end development', 'Testing and Quality Assurance', 'Technical documentation', 'Source code', 'Website or application'],
            }
        },
        {
            pre: '06',
            icon: 'https://media.discordapp.net/attachments/1064586759490519143/1083686439264469093/andershoedholt_3d_scene_depicting_driven_employees_helping_a_cl_3135834c-ce27-45ca-b31d-47f42d3ccd83.png?width=1246&height=1246',
            title: 'Project Management',
            content: `Project management is the process of planning, organizing, and overseeing the development of a digital product, from initial concept to final delivery. It involves ensuring that the project stays on track, on budget, and meets the desired outcomes.
            
            Our project managers have a deep understanding of the UX/UI design process, digital development, and business innovation, so they are able to guide the project to success, providing strategic insights and solving problems along the way.`,
            list:
            {
                title: 'What this could include',
                items: ['Project planning & Budgeting', 'Project schedule', 'Communication plan', 'Status reports', 'Post-project evaluation report'],
            }
        }
    ];

    return (
        <div style={{
            zIndex: 999,
        }}>
            <div className="flex flex-col lg:flex-row relative gap-12">
                <div className="w-full lg:w-1/2">
                    {tabs.map((tab, index) => (
                        <TabHeader
                            key={index}
                            pre={tab.pre}
                            title={tab.title}
                            active={index === activeTab}
                            onClick={() => {
                                setActiveTab(index);
                                setShowOverlay(true);
                            }}
                        />
                    ))}
                </div>
                <div className={`fixed inset-0 bg-white bg-hause-bg p-side-padding-sm overflow-scroll z-50 transition-all duration-300 ${showOverlay ? 'block' : 'hidden'} md:hidden`}>
                    <div onClick={handleOverlayClose} className="absolute top-4 right-4 text-hause-coral text-5xl z-100 cursor-pointer" style={{ zIndex: '9999' }}>&times;</div>
                    <motion.div
                        key={activeTab}
                        initial={{ opacity: 0, y: -15 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 10 }}
                        transition={{ duration: 0.4 }}
                    >
                        <TabIcon icon={tabs[activeTab].icon} />
                        <TabContent>{tabs[activeTab].content}</TabContent>
                        {tabs[activeTab].list &&
                            <TabList list={tabs[activeTab].list} />}
                    </motion.div>
                </div>
                <motion.div
                    key={activeTab}
                    initial={{ opacity: 0, y: -15 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 10 }}
                    transition={{ duration: 0.4 }}
                    className="hidden w-1/2 -mt-28 lg:block"
                >
                    <TabIcon icon={tabs[activeTab].icon} />
                    <TabContent>{tabs[activeTab].content}</TabContent>
                    {tabs[activeTab].list &&
                        <TabList list={tabs[activeTab].list} />}
                </motion.div>
            </div>
        </div>
    );
};

export default Tabbar;