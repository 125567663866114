import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../App';
import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SourceSVG from './utils/SourceSVG';

function MenuItem(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [current, setCurrent] = useState(false);

  useEffect(() => {
    if (location.pathname.startsWith(`/${props.slug}/`) || location.pathname === `/${props.slug}`) {
      setCurrent(true);
    } else {
      setCurrent(false);
    }
  }, [location, props.slug]);

  const handleClick = (event) => {
    event.preventDefault();
    if (props.onModalOpen) {
      props.onModalOpen();
    } else {
      navigate(`/${props.slug}`);
    }
    props.onClick();
  }

  return (
    <Link
      to={`/${props.slug}`}
      onClick={handleClick}
      className={`flex flex-row items-center gap-x-1.5 mx-2 py-3 px-2 lg:px-5 font-regular leading-none transition-all text-center hover:text-hause-coral ${current ? 'bg-hause-coral rounded-md text-white hover:text-white' : 'text-white'}
      ${props.variant === 'featured' ? 'rounded-md mx-2 hover:text-hause-coral hover:border-hause-coral' : ''}
      ${props.variant === 'grouped-feature-l' ? 'mx-0 mr-[-0.5px] hover:text-hause-coral' : ''}
      ${props.variant === 'grouped-feature-r' ? 'mx-0 hover:text-hause-coral' : ''}
      `} style={{
        fontSize: '1rem',
      }}
    >
      {props.icon && <SourceSVG data={props.icon} className="w-2 h-2" />}
      {props.children}
    </Link>
  );
}

export default MenuItem;