import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const PageTransition = ({ pageKey, children }) => {
  const [currentKey, setCurrentKey] = useState(pageKey);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    setIsReady(false);
    setCurrentKey(pageKey);
  }, [pageKey]);

  useEffect(() => {
    setIsReady(true);
  }, [currentKey]);

  return (
    <AnimatePresence mode="wait">
      {isReady && currentKey === pageKey && (
        <motion.div
          key={pageKey}
          initial={{ y: -30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            y: { duration: 0.3, type: "spring", damping: 20, stiffness: 100 },
            opacity: { duration: 0.2 }
          }}
          className="w-full"
        >
          {children}
        </motion.div>
      )
      }
    </AnimatePresence >
  );
};

export default PageTransition;