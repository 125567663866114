import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom';
import HeaderL3 from '../atoms/HeaderL3';
import HeaderL4 from '../atoms/HeaderL4';
import { useContext } from 'react';
import classnames from 'classnames';
import PerspectiveHover from '../effects/PerspectiveHover';
import { request } from 'graphql-request';
import Section from '../Section';

//import { Hero, HERO_QUERY } from '../constants/Sections';
import Loading from '../utils/Loading';
import BuildSection from '../utils/BuildSection';
import Darken from '../effects/Darken';
import ToggleOverflow from '../utils/ToggleOverflow';
//import Cover from '../effects/Cover';
import { AppContext } from '../../App';
import DynamicCover from '../effects/DynamicCover';
import DynamicTitle from '../effects/DynamicTitle';
import { AnimatePresence, motion } from 'framer-motion'

import Hero, { HERO_QUERY } from '../sections/Hero';
import Quote, { QUOTE_QUERY } from '../sections/Quote';
import CollectionAsset, { COLLECTIONASSET_QUERY } from '../atoms/CollectionAsset';
import Column, { COLUMN_QUERY } from '../sections/Column';
import Cover from '../effects/Cover';
import Background from '../effects/Background';
import BackgroundVideo from '../effects/BackgroundVideo';
import ScrollIndicator from '../effects/ScrollIndicator';
import Footer from '../Footer';
import { AnimationOnScroll } from 'react-animation-on-scroll';

import useBreakpoint from '../useBreakpoint';

// Sections
//import * as sections from './Sections';



const Card = ({ type, item, isExpanded }) => {
    //const [isExpanded, setIsExpanded] = useState(isExpanded);
    const { expandedCard, setExpandedCard } = useContext(AppContext);
    const { updateZIndex } = useContext(AppContext);
    const [perspectiveHoverEnabled, setPerspectiveHoverEnabled] = useState(true);
    const [blocks, setBlocks] = useState(null);  // add state value for blocks data
    const [loading, setLoading] = useState(false);  // add state value for loading state


    const elements = item.coverElements.map(element => element.code);
    //console.log(elements2);
    const minRepetitions = 1;
    const maxRepetitions = 7;


    const toggleBodyOverflow = (behavior) => {
        //document.body.classList.remove('overflow-scroll');
        //document.body.classList.remove('overflow-hidden');
        //document.body.classList.add(`overflow-${behavior}`);
    }




    const breakpoints = {
        xs: 0,
        sm: 768,
        md: 992,
        lg: 1200,
        xl: 1600,
    };

    const mainContainerRef = useRef(null);
    const [containerWidth, setContainerWidth] = useState(null);

    useEffect(() => {
        const updateContainerWidth = () => {
            if (mainContainerRef.current) {
                setContainerWidth(mainContainerRef.current.clientWidth);
            }
        };

        updateContainerWidth();

        const resizeObserver = new ResizeObserver(updateContainerWidth);
        if (mainContainerRef.current) {
            resizeObserver.observe(mainContainerRef.current);
        }

        window.addEventListener('resize', updateContainerWidth);

        return () => {
            if (mainContainerRef.current) {
                resizeObserver.unobserve(mainContainerRef.current);
            }
            window.removeEventListener('resize', updateContainerWidth);
        };
    }, []);

    const breakpoint = useBreakpoint(
        breakpoints,
        containerWidth
    );




    const scrollingDivRef = useRef(null);

    const handleCardClick = () => {

        // make GraphQL request for blocks data when card is clicked
        const query = `
          query {
            work(where: { slug: "${item.slug}"}) {
                blocks(first: 100) {
                    __typename
                    ... on Hero ${HERO_QUERY}
                    ... on Quote ${QUOTE_QUERY}
                    ... on Column ${COLUMN_QUERY}
                }
            }
          }
        `;
        setLoading(true);  // set loading state to true
        request('https://eu-central-1-shared-euc1-02.cdn.hygraph.com/content/clbw1lyp8485w01t78e7n5p82/master', query)
            .then((data) => {
                setBlocks(data.work.blocks);
                //setElements2(data.project.coverElements);
                //console.log(data.work.blocks);
                setLoading(false);  // set loading state to false
                updateZIndex(10);
                //setExpandedCard(true, item.slug);
                //setIsExpanded(true);
                toggleBodyOverflow("hidden");
                //console.log(blocks);
                console.log(data.work.blocks)
            });
    };

    const handleCloseButtonClick = () => {
        updateZIndex(0);
        //setExpandedCard(false, null);
        //setIsExpanded(false);
        toggleBodyOverflow("scroll");
    };

    const stateStyling = classnames({
        'fixed m-auto': isExpanded, 'relative': !isExpanded,
        'top-0 inset-x-0 bottom-0': isExpanded, 'inset-auto': !isExpanded,
        'rounded-t-md': !isExpanded, 'rounded-t-none': isExpanded,
        'cursor-pointer': !isExpanded, 'cursor-default': isExpanded,
        'z-1': !isExpanded, 'overflow-scroll scrollbar-hide z-50': isExpanded,
        'expanded': isExpanded && expandedCard.slug === item.slug, // added line
    });

    // useEffect will run whenever isExpanded changes
    useEffect(() => {
        // toggle the perspectiveHoverEnabled state based on the value of isExpanded
        setPerspectiveHoverEnabled(!expandedCard.active);
        if (expandedCard.active && expandedCard.slug === item.slug) {
            //setIsExpanded(true); her

            handleCardClick();
        }
        else {
            //setIsExpanded(false); her
        }
    }, [expandedCard.active]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY === -5) {
                handleCloseButtonClick();
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    switch (type) {
        case 'work':
            return (
                <div>
                    {/*isExpanded && <Darken />*/}

                    <div id="cardScroll" ref={scrollingDivRef} className={stateStyling}>
                        <PerspectiveHover shadow={false} enabled={perspectiveHoverEnabled} scale={true} perspective={false}>
                            <div className={`relative flex flex-col overflow-hidden items-stretch rounded-md w-full ${isExpanded ? 'h-auto bg-hause-bg rounded-none' : 'h-auto border-none'}`}>
                                {/*add a loading div which will be shown when loading is true and will cover the full area of the component*/}
                                {loading && <div style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, zIndex: 9999 }}>

                                </div>}
                                <div
                                    className={`flex items-center justify-center relative overflow-hidden bg-center ${isExpanded ? 'rounded-none pt-[50px] pb-[0px]' : 'h-[430px] rounded-2xl'
                                        }`}
                                    style={{
                                        //backgroundImage: `url(${item.thumbnail.url})`,
                                        objectFit: 'cover',
                                        transform: 'rotateX(0deg) rotateY(0deg)',
                                        backgroundColor: item.brandColor.hex,
                                        height: isExpanded && !item.video ? 'calc(100vh + 100px)' : '',
                                        flexDirection: 'row',
                                        alignItems: isExpanded ? 'flex-end' : 'center',
                                    }}
                                //onClick={!isExpanded ? handleCardClick : ''}
                                >
                                    {isExpanded && blocks &&
                                        <div className="relative bottom-0 w-full z-10">

                                            <div className="relative w-full pb-10" style={{

                                            }}>
                                                <DynamicTitle item={item} color={item.brandColor.hex} />
                                            </div>

                                        </div>}
                                    {item.video && ((breakpoint !== 'xs' && breakpoint !== 'sm') || !isExpanded) &&
                                        <div className="flex items-center justify-center w-full h-full">
                                            <BackgroundVideo item={item} isExpanded={isExpanded} scrollingDivRef={scrollingDivRef} />
                                        </div>
                                    }
                                    {!item.video && item.screen && ((breakpoint !== 'xs' && breakpoint !== 'sm') || !isExpanded) &&
                                        <div className="flex items-center justify-center w-full h-full">
                                            <Background item={item} isExpanded={isExpanded} scrollingDivRef={scrollingDivRef} />
                                        </div>
                                    }
                                    {item.logo && !isExpanded &&
                                        <img
                                            src={item.logo.url}
                                            className="max-h-10 max-w-[200px] z-50"
                                        />}
                                    {isExpanded &&
                                        <DynamicCover elements={elements} minRepetitions={minRepetitions} maxRepetitions={maxRepetitions} />
                                    }

                                    {/*<div className={`${!expandedCard.active ? 'blur-none bg-white' : ''} w-full h-full`}>
                                        <DynamicCover elements={elements} minRepetitions={minRepetitions} maxRepetitions={maxRepetitions} />
                                    </div>*/}

                                </div>
                                <div className={`flex flex-col items-stretch pt-3 pb-5 ${!isExpanded ? 'h-48 px-0' : 'h-auto'}`}>

                                    {!isExpanded &&
                                        <div className="flex flex-col gap-y-4">
                                            <div className="text-left">
                                                <p className="text-left uppercase font-regular text-gray-500 text-sm mb-1">{item.client}</p>
                                                <HeaderL4>{item.title}</HeaderL4>
                                            </div>
                                            <div className="flex flex-wrap">
                                                {item.tags.map((tag) => (
                                                    <span className="uppercase text-hause-coral border border-hause-coral px-3 py-1.5 rounded-full text-xs font-regular tracking-wider mr-2 mb-2">
                                                        {tag}
                                                    </span>
                                                ))}
                                            </div>
                                        </div>}


                                    {isExpanded && expandedCard.active && item.logo &&
                                        <Section>
                                            <div className="flex flex-row">
                                                <div className="rounded-3xl" style={{
                                                    background: item.brandColor.hex,
                                                }}>
                                                    <Section transparent={true}>
                                                        <img
                                                            src={item.logo.url}
                                                            className="relative w-40"
                                                        />
                                                    </Section>
                                                </div>
                                                {/*<div className="w-72 rounded-3xl" style={{
                                                    background: item.brandColor.hex,
                                                    opacity: 0.8,
                                                }}></div>
                                                <div className="w-48" style={{
                                                    background: item.brandColor.hex,
                                                    opacity: 0.3,
                                                }}></div>
                                                <div className="w-36" style={{
                                                    background: item.brandColor.hex,
                                                    opacity: 0.15,
                                                }}></div>*/}
                                            </div>
                                        </Section>
                                    }
                                    {isExpanded && blocks && blocks.map(section => (
                                        <div>
                                            {isExpanded &&
                                                <ScrollIndicator element={scrollingDivRef} color={item.brandColor.hex} breakpoint={breakpoint} />}
                                            <BuildSection section={section} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </PerspectiveHover>
                        {isExpanded &&
                            <Footer />}
                        {/*<Footer color={item.brandColor.hex} />}*/}
                    </div>
                </div>
            );
        default:
            return null;
    }
};

export default Card;